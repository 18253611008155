var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "notification-animation-holder"
  }, [_c('div', {
    staticClass: "notification-holder",
    on: {
      "click": function click($event) {
        return _vm.handleOnClick();
      }
    }
  }, [_vm.notification.type === 'drop' ? _c('div', {
    staticClass: "icon-item"
  }, [_c('Sprite', {
    staticClass: "icon-negative-margin",
    attrs: {
      "image-name": _vm.notification.icon
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "notification callout pt-0",
    class: _vm.classes
  }, [_vm.notification.type === 'error' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "text"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.notification.text)
    }
  })]), _c('close-icon')], 1) : _vm._e(), _vm.notification.type === 'streak' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "text"
  }, [_c('div', [_vm._v(_vm._s(_vm.message))])]), _c('div', {
    staticClass: "icon d-flex align-items-center"
  }, [_c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gold)
    }
  }), _c('div', {
    staticClass: "icon-text",
    domProps: {
      "innerHTML": _vm._s(_vm.notification.text)
    }
  })])]) : _vm._e(), ['hp', 'gp', 'xp', 'mp'].indexOf(_vm.notification.type) !== -1 ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "text"
  }, [_c('div', [_vm._v(_vm._s(_vm.message))])]), _c('div', {
    staticClass: "icon d-flex align-items-center"
  }, [_vm.notification.type === 'hp' ? _c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.health)
    }
  }) : _vm._e(), _vm.notification.type === 'gp' ? _c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gold)
    }
  }) : _vm._e(), _vm.notification.type === 'xp' ? _c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.star)
    }
  }) : _vm._e(), _vm.notification.type === 'mp' ? _c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.mana)
    }
  }) : _vm._e(), _c('div', {
    staticClass: "icon-text",
    domProps: {
      "innerHTML": _vm._s(_vm.notification.text)
    }
  })])]) : _vm._e(), _vm.notification.type === 'damage' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "text"
  }, [_c('div', [_vm._v(_vm._s(_vm.message))])]), _c('div', {
    staticClass: "icon d-flex align-items-center"
  }, [_c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.sword)
    }
  }), _c('div', {
    staticClass: "icon-text",
    domProps: {
      "innerHTML": _vm._s(_vm.notification.text)
    }
  })])]) : _vm._e(), ['info', 'success', 'crit', 'lvl'].indexOf(_vm.notification.type) !== -1 ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "text"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.notification.text)
    }
  })])]) : _vm._e(), _vm.notification.type === 'drop' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "text"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.notification.text)
    }
  })])]) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }