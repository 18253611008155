var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.quest.drop ? _c('div', {
    staticClass: "quest-rewards mb-3"
  }, [_c('div', {
    staticClass: "header d-flex align-items-center",
    on: {
      "click": _vm.toggle
    }
  }, [_c('span', {
    staticClass: "d-flex justify-content-center"
  }, [_vm._m(0)]), _c('SectionButton', {
    attrs: {
      "visible": _vm.opened
    },
    on: {
      "click": _vm.toggle
    }
  })], 1), _vm.opened ? _c('div', {
    staticClass: "content ml-3 mr-3"
  }, [_vm._l(_vm.getDropsList(_vm.quest.drop.items, true), function (drop) {
    return _c('item-with-label', {
      key: drop.key,
      attrs: {
        "item": {},
        "label-class": "purple"
      }
    }, [_c('div', {
      attrs: {
        "slot": "itemImage"
      },
      slot: "itemImage"
    }, [_c('Sprite', {
      attrs: {
        "image-name": _vm.getDropIcon(drop)
      }
    })], 1), _c('div', {
      attrs: {
        "slot": "popoverContent"
      },
      slot: "popoverContent"
    }, [_c('quest-popover', {
      attrs: {
        "item": drop
      }
    })], 1), _c('div', {
      attrs: {
        "slot": "label"
      },
      slot: "label"
    }, [_vm._v(" " + _vm._s(_vm.$t('ownerOnly')) + " ")])]);
  }), _c('item-with-label', {
    attrs: {
      "item": {},
      "label-class": "yellow"
    }
  }, [_c('div', {
    attrs: {
      "slot": "itemImage"
    },
    slot: "itemImage"
  }, [_c('div', {
    staticClass: "icon-48",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.expIcon)
    }
  })]), _c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" " + _vm._s(_vm.$t('amountExp', {
    amount: _vm.quest.drop.exp
  })) + " ")])]), _vm.quest.drop.gp > 0 ? _c('item-with-label', {
    attrs: {
      "item": {},
      "label-class": "yellow"
    }
  }, [_c('div', {
    attrs: {
      "slot": "itemImage"
    },
    slot: "itemImage"
  }, [_c('div', {
    staticClass: "icon-48",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.goldIcon)
    }
  })]), _c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" " + _vm._s(_vm.$t('amountGold', {
    amount: _vm.quest.drop.gp
  })) + " ")])]) : _vm._e(), _vm._l(_vm.getDropsList(_vm.quest.drop.items, false), function (drop) {
    return _c('item-with-label', {
      key: "".concat(drop.type, "_").concat(drop.key),
      staticClass: "item-with-label",
      attrs: {
        "item": {}
      }
    }, [_c('countBadge', {
      attrs: {
        "slot": "badges",
        "show": drop.amount !== 1,
        "count": drop.amount
      },
      slot: "badges"
    }), _c('div', {
      attrs: {
        "slot": "itemImage"
      },
      slot: "itemImage"
    }, [_c('Sprite', {
      attrs: {
        "image-name": _vm.getDropIcon(drop)
      }
    })], 1), _c('div', {
      attrs: {
        "slot": "popoverContent"
      },
      slot: "popoverContent"
    }, [drop.klass ? _c('equipmentAttributesPopover', {
      attrs: {
        "item": drop
      }
    }) : _c('div', [_vm._o(_c('h4', {
      staticClass: "popover-content-title",
      class: {
        'mb-0': !Boolean(drop.notes)
      }
    }, [_vm._v(" " + _vm._s(drop.text) + " ")]), 0, "".concat(drop.type, "_").concat(drop.key)), _vm._o(_c('div', {
      staticClass: "popover-content-text"
    }, [_vm._v(" " + _vm._s(drop.notes) + " ")]), 1, "".concat(drop.type, "_").concat(drop.key))])], 1), _c('div', {
      attrs: {
        "slot": "label"
      },
      slot: "label"
    }, [_vm._v(" " + _vm._s(_vm.$t('newItem')) + " ")])], 1);
  })], 2) : _vm._e()]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "your-rewards d-flex align-items-center"
  }, [_c('span', {
    staticClass: "sparkles",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.sparkles)
    }
  }), _c('span', {
    staticClass: "rewards-title"
  }, [_vm._v(_vm._s(_vm.$t('rewards')))]), _c('span', {
    staticClass: "sparkles mirror",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.sparkles)
    }
  })]);

}]

export { render, staticRenderFns }