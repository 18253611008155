var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "customize-section d-flex flex-column",
    class: {
      'justify-content-between': _vm.editing
    },
    attrs: {
      "id": "skin"
    }
  }, [_c('sub-menu', {
    staticClass: "text-center",
    attrs: {
      "items": _vm.skinSubMenuItems,
      "active-sub-page": _vm.activeSubPage
    },
    on: {
      "changeSubPage": function changeSubPage($event) {
        return _vm.changeSubPage($event);
      }
    }
  }), _c('customize-options', {
    attrs: {
      "items": _vm.userSkins,
      "current-value": _vm.user.preferences.skin
    }
  }), _vm.editing ? _c('customize-banner') : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }