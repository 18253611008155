var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "amazon-pay-button",
    class: {
      disabled: _vm.disabled
    },
    attrs: {
      "id": _vm.buttonId
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }