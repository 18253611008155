var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.emptyItem ? _c('div', [_c('div', {
    staticClass: "item-wrapper"
  }, [_vm._m(0), _vm.label ? _c('span', {
    staticClass: "item-label"
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e()])]) : _c('div', [_c('div', {
    staticClass: "item-wrapper",
    attrs: {
      "id": _vm.itemId
    },
    on: {
      "click": _vm.click
    }
  }, [_c('div', {
    staticClass: "item transition",
    class: {
      'item-active': _vm.active,
      'highlight-border': _vm.highlightBorder
    }
  }, [_vm._t("itemBadge", null, {
    "item": _vm.item
  }), _c('Sprite', {
    staticClass: "item-content",
    attrs: {
      "image-name": _vm.itemContentClass
    }
  })], 2), _vm.label ? _c('span', {
    staticClass: "item-label"
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e()]), _vm.showPopover ? _c('b-popover', {
    attrs: {
      "target": _vm.itemId,
      "triggers": "hover",
      "placement": _vm.popoverPosition,
      "prevent-overflow": false
    }
  }, [_vm._t("popoverContent", null, {
    "item": _vm.item
  })], 2) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "item transition item-empty"
  }, [_c('div', {
    staticClass: "item-content"
  })]);

}]

export { render, staticRenderFns }