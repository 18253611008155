var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "limitedTime",
    class: _vm.availabilityClass
  }, [_c('span', {
    staticClass: "svg-icon inline icon-16",
    domProps: {
      "innerHTML": _vm._s(_vm.availabilityClass === 'expired' ? _vm.icons.clockWhite : _vm.icons.clock)
    }
  }), _c('span', {
    staticClass: "limitedString"
  }, [_vm._v(" " + _vm._s(_vm.limitedString) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }