var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show && _vm.count > 0 ? _c('span', {
    staticClass: "badge badge-pill badge-item badge-count d-flex justify-content-center align-items-center"
  }, [_vm._v(_vm._s(_vm.count))]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }