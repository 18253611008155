var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-notification', {
    attrs: {
      "can-remove": _vm.canRemove,
      "has-icon": false,
      "read-after-click": true,
      "notification": _vm.notification,
      "has-text": false
    },
    on: {
      "click": _vm.action
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center onboarding-complete-content",
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('img', {
    staticClass: "onboarding-complete-banner d-block",
    attrs: {
      "src": require("@/assets/images/onboarding-complete-banner@2x.png")
    }
  }), _vm._m(0), _vm._m(1), _c('div', {
    staticClass: "notifications-buttons"
  }, [_vm._m(2)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', [_vm._v(" " + _vm._s(_vm.$t('onboardingComplete')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "onboarding-complete-text",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('onboardingCompleteDesc'))
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "btn btn-small btn-primary btn-block"
  }, [_vm._v(" " + _vm._s(_vm.$t('viewAchievements')) + " ")]);

}]

export { render, staticRenderFns }