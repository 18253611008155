var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "login-incentives",
      "title": _vm.data.message,
      "size": "md",
      "hide-footer": true
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.data.rewardText ? _c('h3', {
    staticClass: "col-12 text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('unlockedReward', {
    reward: _vm.data.rewardText
  })) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "row reward-row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('avatar', {
    staticClass: "avatar",
    attrs: {
      "member": _vm.user,
      "avatar-only": true,
      "hide-class-badge": true,
      "with-background": true
    }
  })], 1), _vm.nextReward ? _c('div', {
    staticClass: "text-center col-12"
  }, [!_vm.data.rewardText ? _c('div', {
    staticClass: "reward-wrap"
  }, [_vm.nextReward.rewardKey.length === 1 ? _c('div', {
    class: _vm.nextReward.rewardKey[0]
  }) : _vm._e(), _vm._l(_vm.nextReward.rewardKey, function (reward) {
    return _vm.nextReward.rewardKey.length > 1 ? _c('div', {
      key: reward,
      staticClass: "reward",
      class: reward
    }) : _vm._e();
  })], 2) : _vm._e(), _vm.data.rewardText ? _c('div', {
    staticClass: "reward-wrap"
  }, [_vm.data.rewardKey.length === 1 ? _c('div', {
    class: _vm.data.rewardKey[0]
  }) : _vm._e(), _vm._l(_vm.data.rewardKey, function (reward) {
    return _vm.data.rewardKey.length > 1 ? _c('div', {
      key: reward,
      staticClass: "reward",
      class: reward
    }) : _vm._e();
  })], 2) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_vm.data.rewardText ? _c('div', {
    staticClass: "col-12 text-center"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t('earnedRewardForDevotion', {
    reward: _vm.data.rewardText
  })))])]) : _vm._e(), _c('div', {
    staticClass: "col-12 text-center"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t('incentivesDescription')))])]), _vm.data && _vm.data.nextRewardAt ? _c('div', {
    staticClass: "col-12 text-center"
  }, [_c('h3', [_vm._v(" " + _vm._s(_vm.$t('nextRewardUnlocksIn', {
    numberOfCheckinsLeft: _vm.data.nextRewardAt - _vm.user.loginIncentives
  })) + " ")])]) : _vm._e()])]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "col-12 text-center"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('awesome')) + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }