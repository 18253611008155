var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex",
    class: [{
      'opacity-75': _vm.locked
    }, _vm.classOverride]
  }, [_vm.locked ? _vm._m(0) : _vm._e(), _vm._m(1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "svg-icon lock-icon icon-10 mr-1",
    class: _vm.classOverride ? _vm.classOverride : 'gray-200',
    domProps: {
      "innerHTML": _vm._s(_vm.icons.lock)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  });

}]

export { render, staticRenderFns }