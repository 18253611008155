var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "habitica-menu-dropdown dropdown",
    class: {
      open: _vm.isOpen
    },
    attrs: {
      "role": "button",
      "tabindex": "0",
      "aria-pressed": _vm.isPressed
    },
    on: {
      "click": function click($event) {
        return _vm.toggleDropdown();
      },
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") && _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])) return null;
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.toggleDropdown();
      }
    }
  }, [_c('div', {
    staticClass: "habitica-menu-dropdown-toggle"
  }, [_vm._t("dropdown-toggle")], 2), _c('div', {
    staticClass: "dropdown-menu",
    class: {
      'dropdown-menu-right': _vm.right
    }
  }, [_vm._t("dropdown-content")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }