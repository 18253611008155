var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.worldBoss && _vm.worldBoss.active ? _c('base-notification', {
    attrs: {
      "can-remove": false,
      "notification": {},
      "read-after-click": false
    },
    on: {
      "click": _vm.action
    }
  }, [_c('div', {
    staticClass: "background",
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._m(0), _vm._m(1)]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-left"
  }, [_c('div', [_c('div', {
    staticClass: "left-hearts"
  })]), _c('div', {
    staticClass: "float-right"
  }, [_c('div', {
    staticClass: "quest_dysheartener_notification"
  }), _c('div', {
    staticClass: "phobia_dysheartener_notification"
  })])]), _c('div', {
    staticClass: "health-bar d-flex align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.health)
    }
  }), _c('div', {
    staticClass: "boss-health-wrap"
  }, [_c('div', {
    staticClass: "boss-health-bar",
    style: {
      width: parseInt(_vm.bossHp) / _vm.questData.boss.hp * 100 + '%'
    }
  })]), _c('div', {
    staticClass: "pending-damage"
  }, [_vm._m(2), _c('span', [_vm._v("+" + _vm._s(parseInt(_vm.user.party.quest.progress.up) || 0))])])])])]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t('worldBoss')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('questDysheartenerText')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.sword)
    }
  });

}]

export { render, staticRenderFns }