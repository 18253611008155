var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "notifications",
    style: {
      '--current-scrollY': _vm.notificationTopY
    }
  }, [_c('transition-group', {
    staticClass: "animations-holder",
    attrs: {
      "name": "notifications",
      "appear": ""
    }
  }, _vm._l(_vm.visibleNotifications, function (notification, index) {
    return _c('notification', {
      key: notification.uuid,
      staticClass: "notification-item",
      attrs: {
        "notification": notification,
        "visible-amount": index
      },
      on: {
        "clicked": function clicked($event) {
          return _vm.notificationRemoved(notification);
        },
        "hidden": function hidden($event) {
          return _vm.notificationRemoved($event);
        }
      }
    });
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }