var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-banner', {
    attrs: {
      "banner-id": "gems-promo",
      "banner-class": _vm.bannerClass,
      "show": _vm.showGemsPromoBanner,
      "height": "3rem"
    }
  }, [_c('div', {
    staticClass: "content d-flex justify-content-around align-items-center",
    attrs: {
      "slot": "content",
      "aria-label": _vm.$t('gems')
    },
    on: {
      "click": _vm.openGemsModal
    },
    slot: "content"
  }, [_vm.eventName === 'fall_extra_gems' ? _c('img', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "srcset": require("@/assets/images/gems/fall-confetti-left/confetti.png") + ", " + require("@/assets/images/gems/fall-confetti-left/confetti@2x.png") + " 2x, " + require("@/assets/images/gems/fall-confetti-left/confetti@3x.png") + " 3x",
      "src": require("@/assets/images/gems/fall-confetti-left/confetti.png")
    }
  }) : _vm.eventName === 'spooky_extra_gems' ? _c('img', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "srcset": require("@/assets/images/gems/spooky-confetti-left/confetti.png") + ", " + require("@/assets/images/gems/spooky-confetti-left/confetti@2x.png") + " 2x, " + require("@/assets/images/gems/spooky-confetti-left/confetti@3x.png") + " 3x",
      "src": require("@/assets/images/gems/spooky-confetti-left/confetti.png")
    }
  }) : _vm._e(), _c('div', {
    staticClass: "promo-test"
  }, [_vm.eventName === 'fall_extra_gems' ? _c('img', {
    attrs: {
      "srcset": require("@/assets/images/gems/fall-text/text.png") + ", " + require("@/assets/images/gems/fall-text/text@2x.png") + " 2x, " + require("@/assets/images/gems/fall-text/text@3x.png") + " 3x",
      "src": require("@/assets/images/gems/fall-text/text.png")
    }
  }) : _vm.eventName === 'spooky_extra_gems' ? _c('img', {
    attrs: {
      "srcset": require("@/assets/images/gems/spooky-text/text.png") + ", " + require("@/assets/images/gems/spooky-text/text@2x.png") + " 2x, " + require("@/assets/images/gems/spooky-text/text@3x.png") + " 3x",
      "src": require("@/assets/images/gems/spooky-text/text.png")
    }
  }) : _vm._e()]), _vm.eventName === 'fall_extra_gems' ? _c('img', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "srcset": require("@/assets/images/gems/fall-confetti-right/confetti.png") + ", " + require("@/assets/images/gems/fall-confetti-right/confetti@2x.png") + " 2x, " + require("@/assets/images/gems/fall-confetti-right/confetti@3x.png") + " 3x",
      "src": require("@/assets/images/gems/fall-confetti-right/confetti.png")
    }
  }) : _vm.eventName === 'spooky_extra_gems' ? _c('img', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "srcset": require("@/assets/images/gems/spooky-confetti-right/confetti.png") + ", " + require("@/assets/images/gems/spooky-confetti-right/confetti@2x.png") + " 2x, " + require("@/assets/images/gems/spooky-confetti-right/confetti@3x.png") + " 3x",
      "src": require("@/assets/images/gems/spooky-confetti-right/confetti.png")
    }
  }) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }