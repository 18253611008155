export function setUser () {
}

// eslint-disable-next-line no-unused-vars
export function track (properties, options = {}) {
}

// eslint-disable-next-line no-unused-vars
export function updateUser (properties = {}) {
}

export function setup () {
}

export function load () {
}
