var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "class-badge d-flex justify-content-center",
    style: {
      '--badge-size': _vm.badgeSize + 'px'
    }
  }, [_c('div', {
    staticClass: "align-self-center svg-icon",
    attrs: {
      "aria-label": _vm.$t(_vm.memberClass)
    },
    domProps: {
      "innerHTML": _vm._s(_vm.icons[_vm.memberClass])
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }