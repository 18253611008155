var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-banner', {
    attrs: {
      "banner-id": "damage-paused",
      "banner-class": "resting-banner",
      "show": _vm.showRestingBanner,
      "height": "40px"
    }
  }, [_c('div', {
    staticClass: "content",
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_vm._m(0), _vm._m(1), _c('span', {
    staticClass: "separator"
  }, [_vm._v("|")]), _vm._m(2)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "label d-inline d-sm-none"
  }, [_vm._v(_vm._s(_vm.$t('innCheckOutBannerShort')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "label d-none d-sm-inline"
  }, [_vm._v(_vm._s(_vm.$t('innCheckOutBanner')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "resume",
    on: {
      "click": function click($event) {
        return _vm.resumeDamage();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('resumeDamage')))]);

}]

export { render, staticRenderFns }