var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.imageName && _vm.imageName !== '' ? _c('img', {
    staticClass: "pixel-art",
    attrs: {
      "src": _vm.imageUrl()
    }
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }