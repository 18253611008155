var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.items.length > 1 ? _c('div', {
    staticClass: "customize-options mb-4"
  }, _vm._l(_vm.items, function (option) {
    return _c('div', {
      key: option.key,
      staticClass: "outer-option-background",
      class: {
        premium: Boolean(option.gem),
        active: option.active || _vm.currentValue === option.key,
        none: option.none,
        hide: option.hide
      },
      attrs: {
        "id": option.imageName
      },
      on: {
        "click": function click($event) {
          return option.click(option);
        }
      }
    }, [_c('b-popover', {
      attrs: {
        "target": option.imageName,
        "triggers": "hover focus",
        "placement": "bottom",
        "prevent-overflow": false
      }
    }, [_c('strong', [_vm._v(" " + _vm._s(option.text) + " ")])]), _c('div', {
      staticClass: "option"
    }, [!option.none ? _c('Sprite', {
      staticClass: "sprite",
      attrs: {
        "prefix": option.isGear ? 'shop' : 'icon',
        "imageName": option.imageName,
        "image-name": option.imageName
      }
    }) : _c('div', {
      staticClass: "redline-outer"
    }, [_c('div', {
      staticClass: "redline"
    })])], 1)], 1);
  }), 0) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }