var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.achievementClass
  }, [_c('avatar', {
    attrs: {
      "member": _vm.user,
      "avatar-only": true,
      "with-background": true
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }