// this achievement covers all pets of a specific type--classic & quest

const PET_SET_COMPLETE_ACHIEVEMENTS = [
  {
    color: 'Skeleton',
    petAchievement: 'boneToPick',
    petNotificationType: 'ACHIEVEMENT_PET_SET_COMPLETE',
  },
];

export default PET_SET_COMPLETE_ACHIEVEMENTS;
