import { back as baseBack } from './sets/base';

import { back as mysteryBack } from './sets/mystery';
import { back as specialBack } from './sets/special';

const back = {
  base: baseBack,
  mystery: mysteryBack,
  special: specialBack,
};

export default back;
