var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "notification d-flex flex-column justify-content-center text-center"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "btn-secondary mx-auto d-flex",
    on: {
      "click": function click($event) {
        return _vm.showSelectUser();
      }
    }
  }, [_vm._m(2)]), _c('div', {
    staticClass: "notification-remove",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.remove();
      }
    }
  }, [_vm._m(3)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', {
    staticClass: "mx-auto mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('g1g1')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('small', {
    staticClass: "mx-4 mb-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('g1g1Details')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "m-auto"
  }, [_vm._v(" " + _vm._s(_vm.$t('sendGift')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.close)
    }
  });

}]

export { render, staticRenderFns }