var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row mt-3"
  }, [_vm.quest.collect ? _c('div', {
    staticClass: "table-row"
  }, [_c('dt', [_vm._v(_vm._s(_vm.$t('collect') + ':'))]), _c('dd', _vm._l(_vm.quest.collect, function (collect, key) {
    return _c('div', {
      key: key,
      staticClass: "quest-item"
    }, [_c('span', [_vm._v(_vm._s(collect.count) + " " + _vm._s(_vm.getCollectText(collect)))])]);
  }), 0)]) : _vm._e(), _vm.quest.boss ? _c('div', {
    staticClass: "table-row"
  }, [_c('dt', [_vm._v(_vm._s(_vm.$t('bossHP') + ':'))]), _c('dd', [_vm._v(_vm._s(_vm.quest.boss.hp))])]) : _vm._e(), _vm.quest.purchaseType !== 'bundles' ? _c('div', {
    staticClass: "table-row"
  }, [_c('dt', [_vm._v(_vm._s(_vm.$t('difficulty') + ':'))]), _c('dd', _vm._l(_vm.stars(), function (star, index) {
    return _c('div', {
      key: index,
      staticClass: "svg-icon inline icon-16",
      domProps: {
        "innerHTML": _vm._s(_vm.icons[star])
      }
    });
  }), 0)]) : _vm._e()]), _vm.quest.end && !_vm.abbreviated ? _c('div', [_vm._v(" " + _vm._s(_vm.limitedString) + " ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }