var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "member-details d-flex",
    class: {
      condensed: _vm.condensed,
      expanded: _vm.expanded
    },
    on: {
      "click": function click($event) {
        return _vm.showMemberModal(_vm.member);
      }
    }
  }, [_c('div', {
    staticClass: "avatar-container"
  }, [_c('avatar', {
    attrs: {
      "member": _vm.member,
      "hide-class-badge": _vm.classBadgePosition !== 'under-avatar'
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.$emit('click');
      },
      "mouseover": function mouseover($event) {
        return _vm.$emit('onHover');
      },
      "mouseout": function mouseout($event) {
        return _vm.$emit('onHover');
      }
    }
  })], 1), _c('div', {
    staticClass: "member-stats",
    class: {
      'mt-2': !_vm.isHeader
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center profile-first-row"
  }, [_vm.classBadgePosition === 'next-to-name' ? _c('class-badge', {
    attrs: {
      "member-class": _vm.member.stats.class
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-column",
    class: {
      'ml-2': _vm.classBadgePosition === 'next-to-name'
    }
  }, [_c('h3', {
    staticClass: "character-name"
  }, [_vm.member.contributor && _vm.member.contributor.level > 0 && !_vm.disableNameStyling ? _c('span', [_c('user-link', {
    attrs: {
      "user-id": _vm.member._id,
      "name": _vm.member.profile.name,
      "backer": _vm.member.backer,
      "contributor": _vm.member.contributor,
      "show-buffed": _vm.isBuffed,
      "context": 'profile'
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.member.profile.name))]), _vm.isBuffed ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      value: _vm.$t('buffed'),
      expression: "$t('buffed')",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "is-buffed ml-2 mt-n1"
  }, [_c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.buff)
    }
  })]) : _vm._e()])]), _c('div', {
    staticClass: "small-text character-level"
  }, [_vm.member.auth && _vm.member.auth.local && _vm.member.auth.local.username ? _c('span', {
    staticClass: "mr-1"
  }, [_vm._v("@" + _vm._s(_vm.member.auth.local.username))]) : _vm._e(), _vm.member.auth && _vm.member.auth.local && _vm.member.auth.local.username ? _c('span', {
    staticClass: "mr-1"
  }, [_vm._v("•")]) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm.characterLevel))])])])], 1), _c('stats-bar', {
    attrs: {
      "icon": _vm.icons.health,
      "value": _vm.member.stats.hp,
      "max-value": _vm.MAX_HEALTH,
      "tooltip": _vm.$t('health'),
      "progress-class": "bg-health",
      "condensed": _vm.condensed
    }
  }), _c('stats-bar', {
    attrs: {
      "icon": _vm.icons.experience,
      "value": _vm.member.stats.exp,
      "max-value": _vm.toNextLevel,
      "tooltip": _vm.$t('experience'),
      "progress-class": "bg-experience",
      "condensed": _vm.condensed
    }
  }), _vm.hasClass ? _c('stats-bar', {
    attrs: {
      "icon": _vm.icons.mana,
      "value": _vm.member.stats.mp,
      "max-value": _vm.maxMP,
      "tooltip": _vm.$t('mana'),
      "progress-class": "bg-mana",
      "condensed": _vm.condensed
    }
  }) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }