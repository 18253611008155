var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "onboarding-complete",
      "size": "sm",
      "hide-footer": true,
      "hide-header": true
    }
  }, [_c('div', {
    staticClass: "content text-center"
  }, [_vm._m(0), _vm._m(1), _c('img', {
    staticClass: "onboarding-complete-banner d-block",
    attrs: {
      "src": require("@/assets/images/onboarding-complete-banner@2x.png")
    }
  }), _vm._m(2), _vm._m(3), _vm._m(4)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "close-icon svg-icon inline icon-10",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.close)
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', [_vm._v(" " + _vm._s(_vm.$t('onboardingComplete')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "onboarding-complete-text",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('onboardingCompleteDesc'))
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "onboarding-complete-text-small"
  }, [_vm._v(" " + _vm._s(_vm.$t('onboardingCompleteDescSmall')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function click($event) {
        return _vm.closeWithAction();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('viewAchievements')) + " ")]);

}]

export { render, staticRenderFns }