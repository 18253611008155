var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "group-plans-update",
      "title": "New Shared Task Board",
      "size": "lg",
      "hide-footer": "hide-footer",
      "no-close-on-backdrop": true,
      "no-close-on-esc": true,
      "centered": true
    }
  }, [_c('div', {
    staticClass: "w-100 d-flex pt-2 justify-content-center",
    attrs: {
      "slot": "modal-header"
    },
    slot: "modal-header"
  }, [_vm._m(0), _c('div', {
    staticClass: "svg-icon color close-x ml-auto my-auto",
    attrs: {
      "aria-hidden": "true",
      "tabindex": "0"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.icons.close)
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      },
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.close();
      }
    }
  }), _c('img', {
    staticClass: "task-columns",
    attrs: {
      "src": require("@/assets/images/group-plans/task-columns.png"),
      "srcset": require("@/assets/images/group-plans/task-columns@2x.png") + " 2x, " + require("@/assets/images/group-plans/task-columns@3x.png") + " 3x"
    }
  })]), _c('div', {
    staticClass: "d-flex flex-column justify-content-center p-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center mb-3"
  }, [_c('div', {
    staticClass: "svg-icon sparkles my-auto mr-3",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.sparkles)
    }
  }), _vm._m(1), _c('div', {
    staticClass: "svg-icon sparkles my-auto ml-3 flip-x",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.sparkles)
    }
  })]), _c('ul', {
    staticClass: "mb-4 px-4"
  }, [_c('li', [_vm._v(_vm._s(_vm.$t('newGroupsBullet01')))]), _c('li', [_vm._v(_vm._s(_vm.$t('newGroupsBullet02')))]), _c('li', [_vm._v(_vm._s(_vm.$t('newGroupsBullet03')))]), _c('li', [_vm._v(_vm._s(_vm.$t('newGroupsBullet04')))]), _c('li', [_vm._v(_vm._s(_vm.$t('newGroupsBullet05')))]), _c('li', [_vm._v(_vm._s(_vm.$t('newGroupsBullet06')))]), _c('li', [_vm._v(_vm._s(_vm.$t('newGroupsBullet07')))]), _c('li', [_vm._v(_vm._s(_vm.$t('newGroupsBullet08')))]), _c('li', [_vm._v(_vm._s(_vm.$t('newGroupsBullet09')))]), _c('li', [_vm._v(" " + _vm._s(_vm.$t('newGroupsBullet10')) + " "), _c('ul', {
    staticClass: "p-0"
  }, [_c('li', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('newGroupsBullet10a'))
    }
  }), _c('li', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('newGroupsBullet10b'))
    }
  }), _c('li', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('newGroupsBullet10c'))
    }
  })])])]), _c('div', {
    staticClass: "mx-auto",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('newGroupsVisitFAQ'))
    }
  }), _c('div', {
    staticClass: "mx-auto"
  }, [_vm._v(" " + _vm._s(_vm.$t('newGroupsEnjoy')) + " ")]), _c('button', {
    staticClass: "btn btn-primary mt-4 mb-1 mx-auto",
    on: {
      "click": function click($event) {
        return _vm.close();
      },
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.close();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('getStarted')) + " ")])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "mx-auto mt-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('newGroupsWelcome')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', {
    staticClass: "my-auto"
  }, [_vm._v(" " + _vm._s(_vm.$t('newGroupsWhatsNew')) + " ")]);

}]

export { render, staticRenderFns }