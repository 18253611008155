var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "contributor",
      "title": _vm.$t('modalContribAchievement'),
      "size": "md",
      "hide-footer": true
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('achievement-avatar', {
    staticClass: "avatar"
  })], 1), _c('div', {
    staticClass: "col-6 offset-3 text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('contribModal', {
    name: _vm.user.profile.name,
    level: _vm.user.contributor.level
  })) + " "), _c('br'), _c('a', {
    attrs: {
      "href": _vm.$t('conRewardsURL'),
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('contribLink')))]), _c('br'), _c('button', {
    staticClass: "btn btn-primary",
    staticStyle: {
      "margin-top": "1em"
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('huzzah')) + " ")]), _c('br')])]), _c('achievement-footer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }