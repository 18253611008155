var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.displayName ? _c('router-link', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      value: _vm.tierTitle,
      expression: "tierTitle",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "leader user-link d-flex",
    class: _vm.levelStyle(),
    attrs: {
      "to": {
        'name': 'userProfile',
        'params': {
          'userId': _vm.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.displayName) + " "), _c('div', {
    staticClass: "svg-icon icon-12",
    class: {
      'margin-bump': _vm.context === 'profile'
    },
    domProps: {
      "innerHTML": _vm._s(_vm.tierIcon())
    }
  }), _vm.showBuffed ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      value: _vm.$t('buffed'),
      expression: "$t('buffed')",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "is-buffed ml-2 d-flex align-items-center"
  }, [_c('div', {
    staticClass: "svg-icon m-auto",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.buff)
    }
  })]) : _vm._e()]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }