export default [
  'head_special_1',
  'broad_armor_special_1',
  'slim_armor_special_1',
  'head_special_0',
  'slim_armor_special_0',
  'broad_armor_special_0',
  'weapon_special_critical',
  'weapon_special_0',
  'shield_special_0',
  'Pet-Wolf-Cerberus',
  'stable_Pet-Wolf-Cerberus',
  'armor_special_ks2019',
  'slim_armor_special_ks2019',
  'broad_armor_special_ks2019',
  'eyewear_special_ks2019',
  'head_special_ks2019',
  'shield_special_ks2019',
  'weapon_special_ks2019',
  'Pet-Gryphon-Gryphatrice',
  'stable_Pet-Gryphon-Gryphatrice',
  'Mount_Head_Gryphon-Gryphatrice',
  'Mount_Body_Gryphon-Gryphatrice',
  'background_clocktower',
  'background_airship',
  'background_steamworks',
  'Pet_HatchingPotion_Veggie',
  'Pet_HatchingPotion_Dessert',
  'Pet-HatchingPotion-Dessert',
  'quest_windup',
  'Pet-HatchingPotion_Windup',
  'Pet_HatchingPotion_Windup',
  'quest_solarSystem',
  'quest_virtualpet',
  'Pet_HatchingPotion_VirtualPet',
  'Pet-Gryphatrice-Jubilant',
  'stable_Pet-Gryphatrice-Jubilant',
  'back_special_heroicAureole',
  'Pet-HatchingPotion-Funghi',
];
