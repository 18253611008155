var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-notification', {
    attrs: {
      "can-remove": false,
      "has-icon": false,
      "read-after-click": false,
      "notification": {}
    },
    on: {
      "click": _vm.action
    }
  }, [_c('div', {
    staticClass: "text-center",
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('div', {
    staticClass: "username-notification-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('setUsernameNotificationTitle')) + " ")]), _c('div', [_vm._v(_vm._s(_vm.$t('changeUsernameDisclaimer')))]), _c('div', {
    staticClass: "current-username-container mx-auto"
  }, [_c('label', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('currentUsername') + " "))]), _c('label', [_vm._v("@")]), _c('label', [_vm._v(_vm._s(_vm.user.auth.local.username))])]), _c('div', {
    staticClass: "notifications-buttons"
  }, [_c('div', {
    staticClass: "btn btn-small btn-secondary",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeUsername();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('goToSettings')) + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }