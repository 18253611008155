var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "external-link-modal",
      "size": "md"
    }
  }, [_c('div', {
    attrs: {
      "slot": "modal-header"
    },
    slot: "modal-header"
  }, [_c('div', {
    staticClass: "modal-close",
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_c('div', {
    staticClass: "icon-close",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.close)
    }
  })]), _c('div', {
    staticClass: "exclamation-container d-flex align-items-center justify-content-center"
  }, [_vm._m(0)]), _c('h2', [_vm._v(" " + _vm._s(_vm.$t('leaveHabitica')) + " ")])]), _c('div', {
    staticClass: "row leave-warning-text",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('leaveHabiticaText'))
    }
  }), _c('div', {
    staticClass: "skip-modal"
  }, [_vm._v(" " + _vm._s(_vm.$t('skipExternalLinkModal')) + " ")]), _c('div', {
    attrs: {
      "slot": "modal-footer"
    },
    slot: "modal-footer"
  }, [_vm._m(1), _vm._m(2)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon svg-exclamation",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.exclamation)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function click($event) {
        return _vm.proceed();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('continue')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "close-link justify-content-center",
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")]);

}]

export { render, staticRenderFns }