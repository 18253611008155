import { render, staticRenderFns } from "./buyGemsModal.vue?vue&type=template&id=5aa6f027&scoped=true"
import script from "./buyGemsModal.vue?vue&type=script&lang=js"
export * from "./buyGemsModal.vue?vue&type=script&lang=js"
import style0 from "./buyGemsModal.vue?vue&type=style&index=0&id=5aa6f027&prod&lang=scss"
import style1 from "./buyGemsModal.vue?vue&type=style&index=1&id=5aa6f027&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aa6f027",
  null
  
)

export default component.exports