import { render, staticRenderFns } from "./customize-banner.vue?vue&type=template&id=028a2ee8&scoped=true"
import script from "./customize-banner.vue?vue&type=script&lang=js"
export * from "./customize-banner.vue?vue&type=script&lang=js"
import style0 from "./customize-banner.vue?vue&type=style&index=0&id=028a2ee8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "028a2ee8",
  null
  
)

export default component.exports