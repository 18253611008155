var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('invite-modal', {
    attrs: {
      "group": _vm.inviteModalGroup,
      "group-type": _vm.inviteModalGroupType
    }
  }), _c('create-party-modal'), _c('div', {
    staticClass: "row",
    class: {
      'hide-header': _vm.hideHeader
    },
    attrs: {
      "id": "app-header"
    }
  }, [_c('members-modal', {
    attrs: {
      "hide-badge": true
    }
  }), _c('member-details', {
    staticClass: "mr-3",
    attrs: {
      "member": _vm.user,
      "class-badge-position": 'next-to-name',
      "is-header": true,
      "disable-name-styling": true
    }
  }), _vm.hasParty ? _c('div', {
    staticClass: "view-party d-none d-md-flex align-items-center"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function click($event) {
        return _vm.showPartyMembers();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('viewParty')) + " ")])]) : _vm._e(), _vm.hasParty ? _c('div', {
    directives: [{
      name: "resize",
      rawName: "v-resize",
      value: 1500,
      expression: "1500"
    }],
    ref: "partyMembersDiv",
    staticClass: "party-members d-none d-md-flex",
    on: {
      "resized": function resized($event) {
        return _vm.setPartyMembersWidth($event);
      }
    }
  }, _vm._l(_vm.sortedPartyMembers, function (member, $index) {
    return member._id !== _vm.user._id && $index < _vm.membersToShow ? _c('member-details', {
      key: member._id,
      attrs: {
        "member": member,
        "condensed": "condensed",
        "expanded": member._id === _vm.expandedMember,
        "is-header": true,
        "class-badge-position": 'hidden'
      },
      on: {
        "onHover": function onHover($event) {
          return _vm.expandMember(member._id);
        }
      }
    }) : _vm._e();
  }), 1) : _c('div', {
    staticClass: "no-party d-none d-md-flex justify-content-center text-center mr-4"
  }, [_c('div', {
    staticClass: "align-self-center"
  }, [_c('h3', [_vm._v(_vm._s(_vm.user.party._id ? _vm.$t('questWithOthers') : _vm.$t('battleWithFriends')))]), _c('span', {
    staticClass: "small-text",
    domProps: {
      "innerHTML": _vm._s(_vm.user.party._id ? _vm.$t('inviteFriendsParty') : _vm.$t('startPartyDetail'))
    }
  }), _c('br'), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function click($event) {
        return _vm.createOrInviteParty();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.user.party._id ? _vm.$t('findPartyMembers') : _vm.$t('getStarted')) + " ")])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }