var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-notification', {
    attrs: {
      "can-remove": _vm.canRemove,
      "has-icon": false,
      "notification": _vm.notification,
      "read-after-click": true
    },
    on: {
      "click": _vm.action
    }
  }, [_c('div', {
    attrs: {
      "slot": "content"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.notification.data.message)
    },
    slot: "content"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }