import { render, staticRenderFns } from "./profileStats.vue?vue&type=template&id=7f35b742&scoped=true"
import script from "./profileStats.vue?vue&type=script&lang=js"
export * from "./profileStats.vue?vue&type=script&lang=js"
import style0 from "./profileStats.vue?vue&type=style&index=0&id=7f35b742&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f35b742",
  null
  
)

export default component.exports