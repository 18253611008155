var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "rebirth",
      "title": _vm.$t('modalAchievement'),
      "size": "md",
      "hide-footer": true
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('achievement-avatar', {
    staticClass: "avatar"
  })], 1), _c('div', {
    staticClass: "col-6 offset-3 text-center"
  }, [_vm.user.achievements.rebirthLevel < 100 ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('rebirthAchievement', {
    number: _vm.user.achievements.rebirths,
    level: _vm.user.achievements.rebirthLevel
  })) + " ")]) : _vm._e(), _vm.user.achievements.rebirthLevel >= 100 ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('rebirthAchievement100', {
    number: _vm.user.achievements.rebirths
  })) + " ")]) : _vm._e(), _c('br'), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('huzzah')) + " ")])])]), _c('achievement-footer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }