var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "questPopover"
  }, [_vm.item.locked ? _c('h4', {
    staticClass: "popover-content-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('lockedItem')) + " ")]) : _c('h4', {
    staticClass: "popover-content-title"
  }, [_vm._v(" " + _vm._s(_vm.item.text) + " ")]), _vm.item.locked && _vm.item.key === 'lostMasterclasser1' ? _c('div', {
    staticClass: "popover-content-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('questUnlockLostMasterclasser')) + " ")]) : _vm._e(), _vm.item.locked && _vm.item.unlockCondition && _vm.item.unlockCondition.incentiveThreshold ? _c('div', {
    staticClass: "popover-content-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('loginIncentiveQuest', {
    count: _vm.item.unlockCondition.incentiveThreshold
  })) + " ")]) : _vm._e(), _vm.item.locked && _vm.item.previous && _vm.isBuyingDependentOnPrevious(_vm.item) ? _c('div', {
    staticClass: "popover-content-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('unlockByQuesting', {
    title: _vm.item.previous
  })) + " ")]) : _vm._e(), _vm.item.lvl > _vm.user.stats.lvl ? _c('div', {
    staticClass: "popover-content-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('mustLvlQuest', {
    level: _vm.item.lvl
  })) + " ")]) : _vm._e(), !_vm.item.locked ? _c('questInfo', {
    attrs: {
      "quest": _vm.item
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }