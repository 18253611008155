var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "badge badge-round badge-pin align-items-center justify-content-center",
    class: {
      'pinned': _vm.pinned
    }
  }, [_c('div', {
    staticClass: "svg-icon color",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.pin)
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }