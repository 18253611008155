var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-banner', {
    staticClass: "birthday-banner",
    attrs: {
      "banner-id": "birthday-banner",
      "show": _vm.showBirthdayBanner,
      "height": "3rem",
      "can-close": false
    }
  }, [_c('div', {
    staticClass: "content d-flex justify-content-around align-items-center ml-auto mr-auto",
    attrs: {
      "slot": "content",
      "aria-label": _vm.$t('celebrateBirthday')
    },
    on: {
      "click": _vm.showBirthdayModal
    },
    slot: "content"
  }, [_vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon svg-gifts left-gift",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.giftsBirthday)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon svg-ten-birthday",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.tenBirthday)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "announce-text",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('celebrateBirthday'))
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon svg-gifts right-gift",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.giftsBirthday)
    }
  });

}]

export { render, staticRenderFns }