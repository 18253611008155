var render = function render(_c,_vm){
  var _c=_vm._c;

  return _c('span', {
    staticClass: "message-count d-flex align-items-center justify-content-center",
    class: {
      'top-count': _vm.props.top === true,
      'top-count-gray': _vm.props.gray === true
    },
    domProps: {
      "innerHTML": _vm._s(_vm.props.badge || _vm.props.count)
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }