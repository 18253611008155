var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user.party.quest.key && _vm.quests[_vm.user.party.quest.key] ? _c('b-modal', {
    attrs: {
      "id": "quest-invitation",
      "title": _vm.$t('questInvitation'),
      "size": "lg",
      "hide-footer": true
    }
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t('questInvitation')) + " " + _vm._s(_vm.quests[_vm.user.party.quest.key].text()))])]), _c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "pull-right-sm text-center"
  }, [_c('div', {
    staticClass: "col-centered"
  }, [_c('Sprite', {
    attrs: {
      "image-name": "quest_".concat(_vm.quests[_vm.user.party.quest.key].key)
    }
  })], 1), _c('div', {
    attrs: {
      "ng-if": "quests[user.party.quest.key].boss"
    }
  }, [_c('h4', [_vm._v(_vm._s(_vm.quests[_vm.user.party.quest.key].boss.name()))]), _c('p', [_c('strong', [_vm._v(_vm._s(_vm.$t('bossHP')) + " + ': '")]), _vm._v(" " + _vm._s(_vm.quests[_vm.user.party.quest.key].boss.hp) + " ")]), _c('p', [_c('strong', [_vm._v(_vm._s(_vm.$t('bossStrength')) + " + ': '")]), _vm._v(" " + _vm._s(_vm.quests[_vm.user.party.quest.key].boss.str) + " ")])]), _c('div', {
    attrs: {
      "ng-if": "quests[user.party.quest.key].collect"
    }
  }, [_c('p', {
    attrs: {
      "ng-repeat": "(k,v) in quests[user.party.quest.key].collect"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('collect')) + " + ': '")]), _vm._v(" " + _vm._s(_vm.quests[_vm.user.party.quest.key].collect[_vm.k].count) + " " + _vm._s(_vm.quests[_vm.user.party.quest.key].collect[_vm.k].text()) + " ")])])]), _c('div', {
    attrs: {
      "ng-bind-html": "quests[user.party.quest.key].notes()"
    }
  }), _c('div', {
    key: _vm.user.party.quest.key,
    staticClass: "quest-rewards",
    attrs: {
      "header-participant": "$t('rewardsAllParticipants')",
      "header-quest-owner": "$t('rewardsQuestOwner')"
    }
  }), _c('hr'), _c('h5', [_vm._v(_vm._s(_vm.headerParticipant))]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('tr', {
    attrs: {
      "ng-repeat": "drop in _.reject(quest.drop.items, 'onlyOwner')"
    }
  }, [_c('td', [_vm._v(_vm._s(_vm.drop.text()))])]), _c('tr', {
    attrs: {
      "ng-if": "quest.drop.exp > 0"
    }
  }, [_c('td', [_vm._v(_vm._s(_vm.quest.drop.exp) + " " + _vm._s(_vm.$t('experience')))])]), _c('tr', {
    attrs: {
      "ng-if": "quest.drop.gp > 0"
    }
  }, [_c('td', [_vm._v(_vm._s(_vm.quest.drop.gp) + " " + _vm._s(_vm.$t('gold')))])]), _c('tr', {
    attrs: {
      "ng-if": "quest.drop.unlock()"
    }
  }, [_c('td', [_vm._v(_vm._s(_vm.quest.drop.unlock()))])])]), _c('div', {
    attrs: {
      "ng-if": "getQuestOwnerRewards(quest).length > 0"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.headerQuestOwner))]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('tr', {
    attrs: {
      "ng-repeat": "drop in getQuestOwnerRewards(quest)"
    }
  }, [_c('td', [_vm._v(_vm._s(_vm.drop.text()))])])])])]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "ng-click": "questHold = true; $close()"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('askLater')) + " ")]), _c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "ng-click": "questReject(); $close()"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reject')) + " ")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "ng-click": "questAccept(); $close()"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('accept')) + " ")])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }