var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "yesterdaily",
      "size": "m",
      "hide-header": true,
      "hide-footer": true,
      "no-close-on-backdrop": true,
      "no-close-on-esc": true
    },
    on: {
      "hide": function hide($event) {
        return _vm.$emit('hide');
      },
      "hidden": function hidden($event) {
        return _vm.$emit('hidden');
      }
    }
  }, [_c('h1', {
    staticClass: "header-welcome text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('welcomeBack')) + " ")]), _c('p', {
    staticClass: "call-to-action text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('checkOffYesterDailies')) + " ")]), _c('div', {
    staticClass: "tasks-list"
  }, _vm._l(_vm.tasksByType.daily, function (task) {
    return _c('task', {
      key: task.id,
      attrs: {
        "task": task,
        "is-user": true,
        "due-date": _vm.dueDate,
        "is-yesterdaily": true
      }
    });
  }), 1), _c('div', {
    staticClass: "start-day text-center"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "disabled": _vm.isLoading
    },
    on: {
      "click": function click($event) {
        return _vm.processYesterdailies();
      }
    }
  }, [!_vm.isLoading ? _c('span', [_vm._v(_vm._s(_vm.$t('yesterDailiesCallToAction')))]) : _c('loading-spinner')], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }