var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "generic-achievement",
      "title": _vm.data.message,
      "size": "md",
      "hide-header": true
    }
  }, [_c('span', {
    staticClass: "close-icon svg-icon inline icon-10",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.close)
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }), _c('div', {
    staticClass: "content"
  }, [_vm._m(0), _c('div', {
    staticClass: "inner-content"
  }, [_c('div', {
    staticClass: "achievement-background d-flex align-items-center"
  }, [_c('Sprite', {
    staticClass: "icon",
    attrs: {
      "image-name": _vm.achievementClass
    }
  })], 1), _c('h4', {
    staticClass: "title",
    domProps: {
      "innerHTML": _vm._s(_vm.$t(_vm.achievement.titleKey))
    }
  }), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('huzzah')) + " ")])])]), _c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "modal-footer"
    },
    slot: "modal-footer"
  })]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialog-header title"
  }, [_vm._v(" " + _vm._s(_vm.$t('earnedAchievement')) + " ")]);

}]

export { render, staticRenderFns }