var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "customize-section d-flex flex-column",
    class: {
      'justify-content-between': _vm.editing && !_vm.showEmptySection
    },
    attrs: {
      "id": "hair"
    }
  }, [_c('sub-menu', {
    staticClass: "text-center",
    attrs: {
      "items": _vm.hairSubMenuItems,
      "active-sub-page": _vm.activeSubPage
    },
    on: {
      "changeSubPage": function changeSubPage($event) {
        return _vm.changeSubPage($event);
      }
    }
  }), _vm.activeSubPage === 'color' ? _c('div', {
    attrs: {
      "id": "hair-color"
    }
  }, [_c('customize-options', {
    attrs: {
      "items": _vm.userHairColors,
      "current-value": _vm.user.preferences.hair.color
    }
  })], 1) : _vm._e(), _vm.activeSubPage === 'bangs' ? _c('div', {
    attrs: {
      "id": "bangs"
    }
  }, [_c('customize-options', {
    attrs: {
      "items": _vm.hairBangs,
      "current-value": _vm.user.preferences.hair.bangs
    }
  })], 1) : _vm._e(), _vm.activeSubPage === 'style' ? _c('div', {
    attrs: {
      "id": "style"
    }
  }, [_c('customize-options', {
    attrs: {
      "items": _vm.userHairStyles,
      "current-value": _vm.user.preferences.hair.base
    }
  })], 1) : _vm._e(), _vm.activeSubPage === 'facialhair' ? _c('div', {
    attrs: {
      "id": "facialhair"
    }
  }, [_vm.userMustaches.length > 1 ? _c('customize-options', {
    attrs: {
      "items": _vm.userMustaches
    }
  }) : _vm._e(), _vm.userBeards.length > 1 ? _c('customize-options', {
    attrs: {
      "items": _vm.userBeards
    }
  }) : _vm._e(), _vm.showEmptySection ? _c('div', {
    staticClass: "my-5"
  }, [_vm._m(0), _vm._m(1)]) : _vm._e()], 1) : _vm._e(), _vm.editing && !_vm.showEmptySection ? _c('customize-banner') : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', [_vm._v(" " + _vm._s(_vm.$t('noItemsOwned')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "w-50 mx-auto",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('visitCustomizationsShop'))
    }
  });

}]

export { render, staticRenderFns }