var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "subscription-cancel-modal",
      "size": "sm",
      "hide-footer": true,
      "modal-class": ['modal-hidden-footer']
    }
  }, [_c('div', {
    attrs: {
      "slot": "modal-header"
    },
    slot: "modal-header"
  }, [_c('div', {
    staticClass: "icon-container warning-container d-flex align-items-center justify-content-center"
  }, [_vm._m(0)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 modal-body-col"
  }, [_c('h2', [_vm._v(_vm._s(_vm.config && _vm.config.group ? _vm.$t('cancelGroupSub') : _vm.$t('cancelSub')))]), _c('span', {
    staticClass: "cancel-text"
  }, [_vm._v(_vm._s(_vm.config && _vm.config.group ? _vm.$t('confirmCancelGroupPlan') : _vm.$t('confirmCancelSub')))]), _vm._m(1), _vm._m(2)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon warning",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.warning)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn btn-danger mt-4 mb-3",
    on: {
      "click": function click($event) {
        _vm.close();
        _vm.cancelSubscription(_vm.config);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancelSub')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('neverMind')))]);

}]

export { render, staticRenderFns }