var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "avatar-modal",
      "title": "",
      "size": _vm.editing ? 'lg' : 'md',
      "hide-header": true,
      "hide-footer": true,
      "modal-class": {
        'page-2': _vm.modalPage > 1 && !_vm.editing
      },
      "no-close-on-esc": !_vm.editing,
      "no-close-on-backdrop": !_vm.editing
    }
  }, [_vm.editing ? _c('close-x', {
    on: {
      "close": function close($event) {
        return _vm.close();
      }
    }
  }) : _vm._e(), _vm.modalPage === 1 && !_vm.editing ? _c('div', {
    staticClass: "section row welcome-section"
  }, [_c('div', {
    staticClass: "col-6 offset-3 text-center"
  }, [_vm._m(0), _c('div', {
    staticClass: "svg-icon logo",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.logoPurple)
    }
  })])]) : _vm._e(), _vm.editing ? _c('h2', {
    staticClass: "text-center pt-2 mt-4 mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('editAvatar')) + " ")]) : _vm._e(), _vm.modalPage > 1 ? _c('div', {
    staticClass: "avatar-section d-flex justify-content-center",
    class: {
      'page-2': _vm.modalPage === 2
    }
  }, [_c('div', [!_vm.editing ? _c('div', {
    staticClass: "user-creation-bg mt-5"
  }, [_c('avatar', {
    staticClass: "new-user",
    attrs: {
      "member": _vm.user,
      "avatar-only": true,
      "override-top-padding": '0px'
    }
  })], 1) : _c('avatar', {
    attrs: {
      "member": _vm.user,
      "avatar-only": false
    }
  })], 1)]) : _vm._e(), _vm.modalPage === 2 ? _c('div', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container section text-center customize-menu px-5",
    attrs: {
      "id": "options-nav"
    }
  }, [_c('div', {
    staticClass: "row justify-content-around"
  }, [_c('div', {
    staticClass: "menu-container",
    class: {
      active: _vm.activeTopPage === 'body'
    },
    on: {
      "click": function click($event) {
        return _vm.changeTopPage('body', 'size');
      }
    }
  }, [_c('div', {
    staticClass: "menu-item"
  }, [_c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.bodyIcon)
    }
  })]), _vm._m(1), _c('div', {
    staticClass: "indicator"
  })]), _c('div', {
    staticClass: "menu-container",
    class: {
      active: _vm.activeTopPage === 'skin'
    },
    on: {
      "click": function click($event) {
        return _vm.changeTopPage('skin', 'color');
      }
    }
  }, [_c('div', {
    staticClass: "menu-item"
  }, [_c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.skinIcon)
    }
  })]), _vm._m(2), _c('div', {
    staticClass: "indicator"
  })]), _c('div', {
    staticClass: "menu-container",
    class: {
      active: _vm.activeTopPage === 'hair'
    },
    on: {
      "click": function click($event) {
        return _vm.changeTopPage('hair', 'color');
      }
    }
  }, [_c('div', {
    staticClass: "menu-item"
  }, [_c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.hairIcon)
    }
  })]), _vm._m(3), _c('div', {
    staticClass: "indicator"
  })]), _c('div', {
    staticClass: "menu-container",
    class: {
      active: _vm.activeTopPage === 'extra'
    },
    on: {
      "click": function click($event) {
        return _vm.changeTopPage('extra', 'glasses');
      }
    }
  }, [_c('div', {
    staticClass: "menu-item"
  }, [_c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.accessoriesIcon)
    }
  })]), _vm._m(4), _c('div', {
    staticClass: "indicator"
  })]), _vm.editing ? _c('div', {
    staticClass: "menu-container",
    class: {
      active: _vm.activeTopPage === 'backgrounds'
    },
    on: {
      "click": function click($event) {
        return _vm.changeTopPage('backgrounds');
      }
    }
  }, [_c('div', {
    staticClass: "menu-item"
  }, [_c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.backgroundsIcon)
    }
  })]), _vm._m(5), _c('div', {
    staticClass: "indicator"
  })]) : _vm._e()])]), _vm.activeTopPage === 'body' ? _c('body-settings', {
    attrs: {
      "editing": _vm.editing
    }
  }) : _vm._e(), _vm.activeTopPage === 'skin' ? _c('skin-settings', {
    attrs: {
      "editing": _vm.editing
    }
  }) : _vm._e(), _vm.activeTopPage === 'hair' ? _c('hairSettings', {
    attrs: {
      "editing": _vm.editing
    }
  }) : _vm._e(), _vm.activeTopPage === 'extra' ? _c('extraSettings', {
    attrs: {
      "editing": _vm.editing
    }
  }) : _vm._e(), _vm.activeTopPage === 'backgrounds' ? _c('div', {
    staticClass: "section customize-section pt-4",
    attrs: {
      "id": "backgrounds"
    }
  }, [_c('div', {
    staticClass: "row justify-content-center title-row mb-3"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('incentiveBackgrounds')))])]), _vm.standardBackgrounds.length < _vm.standardBackgroundMax ? _c('div', {
    staticClass: "row justify-content-center title-row mb-3"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('incentiveBackgroundsUnlockedWithCheckins')) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "background-row d-flex justify-content-center mb-4"
  }, _vm._l(_vm.standardBackgrounds, function (bg) {
    return _c('div', {
      key: bg.key,
      staticClass: "background-item",
      class: {
        selected: bg.key === _vm.user.preferences.background
      },
      attrs: {
        "id": bg.key
      },
      on: {
        "click": function click($event) {
          return _vm.unlock('background.' + bg.key);
        }
      }
    }, [bg.key === '' ? _c('div', {
      staticClass: "incentive-background deselect"
    }) : _c('div', {
      staticClass: "incentive-background",
      class: "background_".concat(bg.key)
    }, [_c('div', {
      staticClass: "small-rectangle"
    })]), _c('b-popover', {
      attrs: {
        "target": bg.key,
        "triggers": "hover focus",
        "placement": "bottom",
        "prevent-overflow": false,
        "content": bg.notes(_vm.user.preferences.language)
      }
    })], 1);
  }), 0), _vm.user.purchased.background.birthday_bash ? _c('div', [_c('div', {
    staticClass: "row justify-content-center title-row mb-3"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('eventBackgrounds')))])]), _c('div', {
    staticClass: "background-row d-flex justify-content-center mb-4"
  }, _vm._l(_vm.eventBackgrounds, function (bg) {
    return _c('div', {
      key: bg.key,
      staticClass: "background-item",
      class: {
        selected: bg.key === _vm.user.preferences.background
      },
      attrs: {
        "id": bg.key
      },
      on: {
        "click": function click($event) {
          return _vm.unlock('background.' + bg.key);
        }
      }
    }, [_c('Sprite', {
      staticClass: "background",
      attrs: {
        "image-name": "icon_background_".concat(bg.key)
      }
    }), _c('b-popover', {
      attrs: {
        "target": bg.key,
        "triggers": "hover focus",
        "placement": "bottom",
        "prevent-overflow": false,
        "content": bg.notes(_vm.user.preferences.language)
      }
    })], 1);
  }), 0)]) : _vm._e(), _vm.timeTravelBackgrounds.length > 0 ? _c('div', {
    key: "ttbg".concat(_vm.timeTravelBackgrounds.length)
  }, [_c('div', {
    staticClass: "row justify-content-center title-row mb-3"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('timeTravelBackgrounds')))])]), _c('div', {
    staticClass: "background-row d-flex justify-content-center mb-4"
  }, _vm._l(_vm.timeTravelBackgrounds, function (bg) {
    return _c('div', {
      key: bg.key,
      staticClass: "background-item",
      class: {
        selected: bg.key === _vm.user.preferences.background
      },
      attrs: {
        "id": bg.key
      },
      on: {
        "click": function click($event) {
          return _vm.unlock('background.' + bg.key);
        }
      }
    }, [_c('Sprite', {
      staticClass: "background",
      attrs: {
        "image-name": "icon_background_".concat(bg.key)
      }
    }), _c('b-popover', {
      attrs: {
        "target": bg.key,
        "triggers": "hover focus",
        "placement": "bottom",
        "prevent-overflow": false,
        "content": bg.notes(_vm.user.preferences.language)
      }
    })], 1);
  }), 0)]) : _vm._e(), _vm.monthlyBackgrounds.length > 0 ? _c('div', {
    key: "monthly".concat(_vm.monthlyBackgrounds.length)
  }, [_c('div', {
    staticClass: "row text-center title-row mb-3"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('monthlyBackgrounds')))])]), _c('div', {
    staticClass: "background-row d-flex justify-content-center mx-auto mb-4 px-5"
  }, _vm._l(_vm.monthlyBackgrounds, function (bg) {
    return _c('div', {
      key: bg.key,
      staticClass: "background-item",
      class: {
        selected: bg.key === _vm.user.preferences.background
      },
      attrs: {
        "id": bg.key
      },
      on: {
        "click": function click($event) {
          return _vm.unlock('background.' + bg.key);
        }
      }
    }, [_c('Sprite', {
      staticClass: "background",
      attrs: {
        "image-name": "icon_background_".concat(bg.key)
      }
    }), _c('b-popover', {
      attrs: {
        "target": bg.key,
        "triggers": "hover focus",
        "placement": "bottom",
        "prevent-overflow": false,
        "content": bg.notes(_vm.user.preferences.language)
      }
    })], 1);
  }), 0)]) : _vm._e(), _c('customize-banner', {
    staticClass: "padding-fix"
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.modalPage === 3 && !_vm.editing ? _c('div', {
    staticClass: "container interests-section"
  }, [_c('div', {
    staticClass: "section row"
  }, [_c('div', {
    staticClass: "col-12 text-center"
  }, [_c('h2', [_vm._v(_vm._s(_vm.$t('wantToWorkOn')))])])]), _c('div', {
    staticClass: "section row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "task-option"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.taskCategories,
      expression: "taskCategories"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": "work",
      "type": "checkbox",
      "value": "work"
    },
    domProps: {
      "checked": Array.isArray(_vm.taskCategories) ? _vm._i(_vm.taskCategories, "work") > -1 : _vm.taskCategories
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.taskCategories,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "work",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.taskCategories = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.taskCategories = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.taskCategories = $$c;
        }
      }
    }
  }), _vm._m(6)])]), _c('div', {
    staticClass: "task-option"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.taskCategories,
      expression: "taskCategories"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": "exercise",
      "type": "checkbox",
      "value": "exercise"
    },
    domProps: {
      "checked": Array.isArray(_vm.taskCategories) ? _vm._i(_vm.taskCategories, "exercise") > -1 : _vm.taskCategories
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.taskCategories,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "exercise",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.taskCategories = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.taskCategories = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.taskCategories = $$c;
        }
      }
    }
  }), _vm._m(7)])]), _c('div', {
    staticClass: "task-option"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.taskCategories,
      expression: "taskCategories"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": "health_wellness",
      "type": "checkbox",
      "value": "health_wellness"
    },
    domProps: {
      "checked": Array.isArray(_vm.taskCategories) ? _vm._i(_vm.taskCategories, "health_wellness") > -1 : _vm.taskCategories
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.taskCategories,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "health_wellness",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.taskCategories = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.taskCategories = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.taskCategories = $$c;
        }
      }
    }
  }), _vm._m(8)])]), _c('div', {
    staticClass: "task-option"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.taskCategories,
      expression: "taskCategories"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": "school",
      "type": "checkbox",
      "value": "school"
    },
    domProps: {
      "checked": Array.isArray(_vm.taskCategories) ? _vm._i(_vm.taskCategories, "school") > -1 : _vm.taskCategories
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.taskCategories,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "school",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.taskCategories = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.taskCategories = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.taskCategories = $$c;
        }
      }
    }
  }), _vm._m(9)])])]), _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "task-option"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.taskCategories,
      expression: "taskCategories"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": "chores",
      "type": "checkbox",
      "value": "chores"
    },
    domProps: {
      "checked": Array.isArray(_vm.taskCategories) ? _vm._i(_vm.taskCategories, "chores") > -1 : _vm.taskCategories
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.taskCategories,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "chores",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.taskCategories = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.taskCategories = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.taskCategories = $$c;
        }
      }
    }
  }), _vm._m(10)])]), _c('div', {
    staticClass: "task-option"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.taskCategories,
      expression: "taskCategories"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": "creativity",
      "type": "checkbox",
      "value": "creativity"
    },
    domProps: {
      "checked": Array.isArray(_vm.taskCategories) ? _vm._i(_vm.taskCategories, "creativity") > -1 : _vm.taskCategories
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.taskCategories,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "creativity",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.taskCategories = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.taskCategories = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.taskCategories = $$c;
        }
      }
    }
  }), _vm._m(11)])]), _c('div', {
    staticClass: "task-option"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.taskCategories,
      expression: "taskCategories"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": "self_care",
      "type": "checkbox",
      "value": "self_care"
    },
    domProps: {
      "checked": Array.isArray(_vm.taskCategories) ? _vm._i(_vm.taskCategories, "self_care") > -1 : _vm.taskCategories
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.taskCategories,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "self_care",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.taskCategories = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.taskCategories = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.taskCategories = $$c;
        }
      }
    }
  }), _vm._m(12)])])])])]) : _vm._e(), !_vm.editing ? _c('div', {
    staticClass: "section d-flex justify-content-center justin-outer-section",
    class: {
      top: _vm.modalPage > 1
    }
  }, [_c('div', {
    staticClass: "justin-section d-flex align-items-center"
  }, [_c('div', {
    staticClass: "featured-label"
  }, [_c('span', {
    staticClass: "rectangle"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("Justin")]), _c('span', {
    staticClass: "rectangle"
  })]), _c('div', {
    staticClass: "justin-message"
  }, [_c('div', {
    staticClass: "corner-decoration",
    style: {
      top: '-2px',
      right: '-2px'
    }
  }), _c('div', {
    staticClass: "corner-decoration",
    style: {
      top: '-2px',
      left: '-2px'
    }
  }), _c('div', {
    staticClass: "corner-decoration",
    style: {
      bottom: '-2px',
      right: '-2px'
    }
  }), _c('div', {
    staticClass: "corner-decoration",
    style: {
      bottom: '-2px',
      left: '-2px'
    }
  }), _vm.modalPage === 1 ? _c('div', [_vm._m(13), _vm._m(14)]) : _vm._e(), _vm.modalPage === 2 ? _c('div', [_c('p', [_vm._v(_vm._s(_vm.$t('justinIntroMessageAppearance')))])]) : _vm._e(), _vm.modalPage === 3 ? _c('div', [_vm._m(15)]) : _vm._e()]), _c('div', {
    staticClass: "npc-justin-textbox",
    style: {
      'background-image': _vm.imageURL
    }
  })])]) : _vm._e(), _vm.modalPage === 1 ? _c('div', {
    staticClass: "section mr-5 ml-5 first-page-footer"
  }, [_c('username-form', {
    attrs: {
      "avatar-intro": true
    },
    on: {
      "usernameConfirmed": function usernameConfirmed($event) {
        _vm.modalPage += 1;
      }
    }
  }), _c('div', {
    staticClass: "small text-center",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('usernameTOSRequirements'))
    }
  })], 1) : _vm._e(), !_vm.editing && !(_vm.modalPage === 1) ? _c('div', {
    staticClass: "section container footer"
  }, [_c('div', {
    staticClass: "footer-left"
  }, [_vm.modalPage > 1 ? _c('div', {
    staticClass: "prev-outer",
    on: {
      "click": function click($event) {
        return _vm.prev();
      }
    }
  }, [_c('div', {
    staticClass: "prev-arrow svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.arrowLeft)
    }
  }), _vm._m(16)]) : _vm._e()]), _c('div', {
    staticClass: "footer-center text-center circles"
  }, [_c('div', {
    staticClass: "circle",
    class: {
      active: _vm.modalPage === 1
    }
  }), _c('div', {
    staticClass: "circle",
    class: {
      active: _vm.modalPage === 2
    }
  }), _c('div', {
    staticClass: "circle",
    class: {
      active: _vm.modalPage === 3
    }
  })]), _c('div', {
    staticClass: "footer-right"
  }, [_vm.modalPage < 3 ? _c('div', {
    staticClass: "next-outer",
    on: {
      "click": function click($event) {
        return _vm.next();
      }
    }
  }, [_vm._m(17), _c('div', {
    staticClass: "next-arrow svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.arrowRight)
    }
  })]) : _vm._e(), _vm.modalPage === 3 && !_vm.loading ? _c('div', {
    staticClass: "next-outer",
    class: {
      disabled: _vm.taskCategories.length === 0
    },
    on: {
      "click": function click($event) {
        return _vm.done();
      }
    }
  }, [_vm._m(18), _c('div', {
    staticClass: "next-arrow svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.arrowRight)
    }
  })]) : _vm._e()])]) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', [_vm._v(" " + _vm._s(_vm.$t('welcomeTo')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', [_vm._v(_vm._s(_vm.$t('bodyBody')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', [_vm._v(_vm._s(_vm.$t('skin')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', [_vm._v(_vm._s(_vm.$t('hair')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', [_vm._v(_vm._s(_vm.$t('extra')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', [_vm._v(_vm._s(_vm.$t('backgrounds')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "work"
    }
  }, [_vm._v(_vm._s(_vm.$t('work')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "exercise"
    }
  }, [_vm._v(_vm._s(_vm.$t('exercise')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "health_wellness"
    }
  }, [_vm._v(_vm._s(_vm.$t('health_wellness')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "school"
    }
  }, [_vm._v(_vm._s(_vm.$t('school')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "chores"
    }
  }, [_vm._v(_vm._s(_vm.$t('chores')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "creativity"
    }
  }, [_vm._v(_vm._s(_vm.$t('creativity')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "self_care"
    }
  }, [_vm._v(_vm._s(_vm.$t('self_care')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('justinIntroMessage1'))
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" " + _vm._s(_vm.$t('justinIntroMessageUsername')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" " + _vm._s(_vm.$t('justinIntroMessage3')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "prev"
  }, [_vm._v(" " + _vm._s(_vm.$t('prev')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "next"
  }, [_vm._v(" " + _vm._s(_vm.$t('next')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "next"
  }, [_vm._v(" " + _vm._s(_vm.$t('finish')) + " ")]);

}]

export { render, staticRenderFns }