import bangs from './bangs';
import base from './base';
import beard from './beard';
import color from './color';
import flower from './flower';
import mustache from './mustache';

export default {
  color,
  base,
  bangs,
  flower,
  beard,
  mustache,
};
