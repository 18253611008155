var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "loading-gryphon-wrapper"
  }, [_vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon loading-gryphon",
    style: {
      width: "".concat(_vm.width, "px"),
      height: "".concat(_vm.height, "px")
    },
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gryphon)
    }
  });

}]

export { render, staticRenderFns }