var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "item-wrapper",
    attrs: {
      "id": _vm.itemId,
      "tabindex": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.click();
      },
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.click();
      }
    }
  }, [_c('div', {
    staticClass: "item",
    class: _vm.getItemClasses()
  }, [_vm._t("badges", null, {
    "item": _vm.item,
    "emptyItem": _vm.emptyItem
  }), _c('div', {
    staticClass: "image"
  }, [_vm._m(0), _vm._t("itemImage", null, {
    "item": _vm.item
  })], 2), _c('div', {
    staticClass: "d-flex label-holder align-items-center justify-content-center",
    class: _vm.labelClass
  }, [_vm._t("label", null, {
    "item": _vm.item
  })], 2)], 2)]), _vm.showPopover ? _c('b-popover', {
    attrs: {
      "target": _vm.itemId,
      "triggers": "hover focus",
      "placement": _vm.popoverPosition
    }
  }, [_vm._t("popoverContent", null, {
    "item": _vm.item
  })], 2) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Sprite', {
    attrs: {
      "image-name": _vm.item.class
    }
  });

}]

export { render, staticRenderFns }