var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('menu-dropdown', {
    staticClass: "item-notifications",
    attrs: {
      "right": true,
      "open-status": _vm.openStatus
    },
    on: {
      "toggled": _vm.handleOpenStatusChange
    }
  }, [_c('div', {
    attrs: {
      "slot": "dropdown-toggle"
    },
    slot: "dropdown-toggle"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      value: _vm.$t('notifications'),
      expression: "$t('notifications')",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    attrs: {
      "aria-label": _vm.$t('notifications')
    }
  }, [_vm.notificationsCount > 0 || _vm.hasSpecialBadge ? _c('message-count', {
    attrs: {
      "count": _vm.notificationsCount,
      "top": true,
      "gray": !_vm.hasUnseenNotifications && !_vm.hasSpecialBadge,
      "badge": _vm.hasSpecialBadge ? _vm.icons.starBadge : null
    }
  }) : _vm._e(), _c('div', {
    staticClass: "top-menu-icon svg-icon notifications",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.notifications)
    }
  })], 1)]), _vm.openStatus === 1 ? _c('div', {
    attrs: {
      "slot": "dropdown-content"
    },
    slot: "dropdown-content"
  }, [_c('div', {
    staticClass: "dropdown-item dropdown-separated d-flex justify-content-between dropdown-inactive align-items-center",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._m(0), _c('a', {
    staticClass: "small-link",
    attrs: {
      "disabled": _vm.notificationsCount === 0
    },
    on: {
      "click": _vm.dismissAll
    }
  }, [_vm._v(_vm._s(_vm.$t('dismissAll')))])]), _c('world-boss'), _vm.showOnboardingGuide ? _c('onboarding-guide', {
    attrs: {
      "never-seen": _vm.hasSpecialBadge
    }
  }) : _vm._e(), _vm._l(_vm.notifications, function (notification) {
    return _c(notification.type, {
      key: notification.id,
      tag: "component",
      attrs: {
        "notification": notification,
        "can-remove": !_vm.isActionable(notification)
      }
    });
  }), _vm.notificationsCount === 0 && !_vm.showOnboardingGuide ? _c('div', {
    staticClass: "dropdown-item dropdown-separated d-flex justify-content-center dropdown-inactive no-notifications flex-column"
  }, [_c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.success)
    }
  }), _c('h2', [_vm._v(_vm._s(_vm.$t('noNotifications')))]), _c('p', [_vm._v(_vm._s(_vm.$t('noNotificationsText')))])]) : _vm._e()], 2) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h4', {
    staticClass: "dropdown-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('notifications')) + " ")]);

}]

export { render, staticRenderFns }