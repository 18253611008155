var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center"
  }, _vm._l(_vm.currencies, function (currency) {
    return _c('div', {
      key: currency.key,
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "svg-icon icon-16 ml-1",
      domProps: {
        "innerHTML": _vm._s(currency.icon)
      }
    }), _c('div', {
      staticClass: "currency-value mx-1 my-auto",
      class: {
        'notEnough': currency.notEnough
      }
    }, [_vm._v(" " + _vm._s(_vm._f("roundBigNumber")(currency.value)) + " ")])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }