var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "death",
      "title": _vm.$t('lostAllHealth'),
      "size": "md",
      "hide-footer": true,
      "no-close-on-esc": "no-close-on-esc",
      "no-close-on-backdrop": "no-close-on-backdrop"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "hero-stats"
  }, [_c('div', {
    staticClass: "meter-label",
    attrs: {
      "tooltip": _vm.$t('health')
    }
  }, [_c('span', {
    staticClass: "glyphicon glyphicon-heart"
  })]), _c('div', {
    staticClass: "meter health",
    attrs: {
      "tooltip": Math.round(_vm.user.stats.hp * 100) / 100
    }
  }, [_c('div', {
    staticClass: "bar",
    style: _vm.barStyle
  })]), _c('avatar', {
    attrs: {
      "member": _vm.user,
      "sleep": true,
      "avatar-only": true,
      "with-background": true
    }
  }), _c('span', {
    staticClass: "knockout"
  })], 1)]), _c('div', {
    staticClass: "col-6 offset-3"
  }, [_c('h4', {
    staticClass: "dont-despair"
  }, [_vm._v(" " + _vm._s(_vm.$t('dontDespair')) + " ")]), _c('p', {
    staticClass: "death-penalty"
  }, [_vm._v(" " + _vm._s(_vm.$t('deathPenaltyDetails')) + " ")])])]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "col-12 text-center"
  }, [_c('button', {
    staticClass: "btn btn-danger",
    on: {
      "click": function click($event) {
        return _vm.revive();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('refillHealthTryAgain')) + " ")]), _c('h4', {
    staticClass: "text-center",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('dyingOftenTips'))
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }