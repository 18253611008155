var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-menu text-center"
  }, _vm._l(_vm.items, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "sub-menu-item",
      class: {
        active: _vm.activeSubPage === item.id
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('changeSubPage', item.id);
        }
      }
    }, [_vm._o(_c('strong', [_vm._v(_vm._s(item.label))]), 0, item.id)]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }