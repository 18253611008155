var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "bailey-header d-flex align-items-center mb-3"
  }, [_c('div', {
    staticClass: "mr-3",
    class: _vm.npcClass('bailey')
  }), _vm._m(0)]), _vm._l(_vm.posts, function (post, index) {
    return _c('div', {
      key: post._id,
      staticClass: "static-view bailey",
      class: {
        'bailey-last': index == _vm.posts.length - 1
      },
      attrs: {
        "id": "bailey"
      }
    }, [!post.published ? _c('small', {
      staticClass: "draft"
    }, [_vm._v("DRAFT")]) : _vm._e(), _c('h2', {
      staticClass: "title"
    }, [_vm._v(" " + _vm._s(_vm.getPostDate(post)) + " - " + _vm._s(post.title.toUpperCase()) + " ")]), _c('hr'), _c('div', {
      domProps: {
        "innerHTML": _vm._s(_vm.renderMarkdown(post.text))
      }
    }), _c('small', [_vm._v("by " + _vm._s(post.credits))])]);
  })], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h1', [_vm._v(" " + _vm._s(_vm.$t('newStuff')) + " ")]);

}]

export { render, staticRenderFns }