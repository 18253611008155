var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "report-flag",
      "size": "md",
      "hide-footer": true,
      "hide-header": true
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "heading"
  }, [_c('h5', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('abuseFlagModalHeading'))
    }
  })]), _c('div', [_c('span', {
    staticClass: "svg-icon close-icon icon-16 color",
    attrs: {
      "aria-hidden": "true",
      "tabindex": "0"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.icons.close)
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      },
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.close();
      }
    }
  })]), _c('blockquote', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.abuseObject.text)
    }
  })]), _c('div', [_c('strong', [_vm._v(_vm._s(_vm.$t('whyReportingPost')))]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.reportComment,
      expression: "reportComment"
    }],
    staticClass: "form-control",
    attrs: {
      "placeholder": _vm.$t('whyReportingPostPlaceholder')
    },
    domProps: {
      "value": _vm.reportComment
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.reportComment = $event.target.value;
      }
    }
  })]), _c('p', {
    staticClass: "report-guidelines",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('abuseFlagModalBody', _vm.abuseFlagModalBody))
    }
  })]), _c('div', {
    staticClass: "buttons text-center"
  }, [_c('div', {
    staticClass: "button-spacing"
  }, [_c('button', {
    staticClass: "btn btn-danger",
    class: {
      disabled: !_vm.reportComment
    },
    on: {
      "click": function click($event) {
        return _vm.reportAbuse();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('report')) + " ")])]), _c('div', {
    staticClass: "button-spacing"
  }, [_c('a', {
    staticClass: "cancel-link",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.close();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")])])]), _vm.user.contributor.admin ? _c('div', {
    staticClass: "reset-flag-count d-flex",
    on: {
      "click": function click($event) {
        return _vm.clearFlagCount();
      }
    }
  }, [_c('span', {
    staticClass: "my-auto",
    on: {
      "click": function click($event) {
        return _vm.clearFlagCount();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('resetFlags')) + " ")])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }