var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "loading-spinner",
    class: {
      'loading-spinner-purple': _vm.darkColor
    },
    attrs: {
      "role": "text",
      "aria-label": _vm.$t('loading')
    }
  }, [_c('div'), _c('div'), _c('div')]);

}]

export { render, staticRenderFns }