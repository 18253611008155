var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "select-member-modal",
      "size": "lg",
      "hide-footer": true
    },
    on: {
      "change": function change($event) {
        return _vm.onChange($event);
      }
    }
  }, [_c('div', {
    staticClass: "header-wrap",
    attrs: {
      "slot": "modal-header"
    },
    slot: "modal-header"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_vm._m(0)]), _c('div', {
    staticClass: "col-6"
  }, [_c('button', {
    staticClass: "close",
    attrs: {
      "type": "button",
      "aria-label": "Close"
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-6"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchTerm,
      expression: "searchTerm"
    }],
    staticClass: "form-control search",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('search')
    },
    domProps: {
      "value": _vm.searchTerm
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.searchTerm = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "col-4 offset-2"
  }, [_c('span', {
    staticClass: "dropdown-label"
  }, [_vm._v(_vm._s(_vm.$t('sortBy')))]), _c('b-dropdown', {
    attrs: {
      "text": _vm.$t('sort'),
      "right": "right"
    }
  }, _vm._l(_vm.sortOptions, function (sortOption) {
    return _c('b-dropdown-item', {
      key: sortOption.value,
      on: {
        "click": function click($event) {
          return _vm.sort(sortOption.value);
        }
      }
    }, [_vm._v(" " + _vm._s(sortOption.text) + " ")]);
  }), 1)], 1)])]), _vm._l(_vm.sortedMembers, function (member) {
    return _c('div', {
      key: member._id,
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-10"
    }, [_c('member-details', {
      attrs: {
        "member": member
      }
    })], 1), _c('div', {
      staticClass: "col-2 actions"
    }, [_c('button', {
      staticClass: "btn btn-primary",
      on: {
        "click": function click($event) {
          return _vm.selectMember(member);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('select')) + " ")])])]);
  }), _vm.members.length > 3 ? _c('div', {
    staticClass: "row gradient"
  }) : _vm._e(), _vm.isLoadMoreAvailable ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 text-center"
  }, [_c('button', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function click($event) {
        return _vm.loadMoreMembers();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('loadMore')) + " ")])])]) : _vm._e()], 2)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h1', [_vm._v(" " + _vm._s(_vm.$t('selectPartyMember')) + " ")]);

}]

export { render, staticRenderFns }