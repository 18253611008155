var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "standard-page",
    attrs: {
      "id": "stats"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "stats-section-equipment col-12 col-md-6"
  }, [_c('h2', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('equipment')) + " ")]), _c('div', {
    staticClass: "well"
  }, _vm._l(_vm.equipTypes, function (label, key) {
    return _c('div', {
      key: key,
      staticClass: "item-wrapper"
    }, [label !== 'skip' ? _c('div', {
      staticClass: "gear box",
      class: {
        white: _vm.isUsed(_vm.equippedItems, key)
      },
      attrs: {
        "id": key
      }
    }, [_vm.isUsed(_vm.equippedItems, key) ? _c('Sprite', {
      attrs: {
        "image-name": "shop_".concat(_vm.equippedItems[key])
      }
    }) : _vm._e()], 1) : _vm._e(), label !== 'skip' && _vm.equippedItems[key] && _vm.equippedItems[key].indexOf('base_0') === -1 ? _c('b-popover', {
      attrs: {
        "target": key,
        "triggers": "hover",
        "placement": 'bottom',
        "prevent-overflow": false
      }
    }, [_c('h4', {
      staticClass: "popover-title-only"
    }, [_vm._v(" " + _vm._s(_vm.getGearTitle(_vm.equippedItems[key])) + " ")]), _c('attributesGrid', {
      staticClass: "attributesGrid",
      attrs: {
        "item": _vm.content.gear.flat[_vm.equippedItems[key]],
        "user": _vm.user
      }
    })], 1) : _vm._e(), label !== 'skip' ? _c('span', {
      staticClass: "gear-label"
    }, [_vm._v(" " + _vm._s(label) + " ")]) : _vm._e()], 1);
  }), 0)]), _c('div', {
    staticClass: "stats-section-costume col-12 col-md-6"
  }, [_c('h2', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('costume')) + " ")]), _c('div', {
    staticClass: "well"
  }, _vm._l(_vm.equipTypes, function (label, key) {
    return _c('div', {
      key: key,
      staticClass: "item-wrapper"
    }, [label !== 'skip' ? _c('div', {
      staticClass: "gear box",
      class: {
        white: _vm.isUsed(_vm.costumeItems, key)
      },
      attrs: {
        "id": key + 'C'
      }
    }, [_vm.isUsed(_vm.costumeItems, key) ? _c('Sprite', {
      attrs: {
        "image-name": "shop_".concat(_vm.costumeItems[key])
      }
    }) : _vm._e()], 1) : _vm._e(), label === 'skip' ? _c('div', {
      staticClass: "gear box",
      class: {
        white: _vm.user.preferences.background
      },
      staticStyle: {
        "overflow": "hidden"
      }
    }, [_c('Sprite', {
      attrs: {
        "image-name": 'icon_background_' + _vm.user.preferences.background
      }
    })], 1) : _vm._e(), label !== 'skip' && _vm.costumeItems[key] && _vm.costumeItems[key].indexOf('base_0') === -1 ? _c('b-popover', {
      attrs: {
        "target": key + 'C',
        "triggers": "hover",
        "placement": 'bottom',
        "prevent-overflow": false
      }
    }, [_c('h4', {
      staticClass: "popover-title-only"
    }, [_vm._v(" " + _vm._s(_vm.getGearTitle(_vm.costumeItems[key])) + " ")]), _c('attributesGrid', {
      staticClass: "attributesGrid",
      attrs: {
        "item": _vm.content.gear.flat[_vm.costumeItems[key]],
        "user": _vm.user
      }
    })], 1) : _vm._e(), label !== 'skip' ? _c('span', {
      staticClass: "gear-label"
    }, [_vm._v(" " + _vm._s(label) + " ")]) : _c('span', {
      staticClass: "gear-label"
    }, [_vm._v(" " + _vm._s(_vm.$t('background')) + " ")])], 1);
  }), 0)])]), _c('div', {
    staticClass: "row pet-mount-row"
  }, [_c('div', {
    staticClass: "stats-section-pets col-12 col-md-6"
  }, [_vm._m(0), _c('div', {
    staticClass: "well pet-mount-well"
  }, [_c('div', {
    staticClass: "pet-mount-well-image"
  }, [_c('div', {
    staticClass: "box",
    class: {
      white: _vm.user.items.currentPet
    }
  }, [_c('Sprite', {
    attrs: {
      "image-name": _vm.user.items.currentPet ? "stable_Pet-".concat(_vm.user.items.currentPet) : ''
    }
  })], 1)]), _c('div', {
    staticClass: "pet-mount-well-text"
  }, [_c('div', [_vm._v(_vm._s(_vm.formatAnimal(_vm.user.items.currentPet, 'pet')))]), _c('div', [_c('strong', [_vm._v(_vm._s(_vm.$t('petsFound')) + ":")]), _vm._v(" " + _vm._s(_vm.totalCount(_vm.user.items.pets)) + " ")]), _c('div', [_c('strong', [_vm._v(_vm._s(_vm.$t('beastMasterProgress')) + ":")]), _vm._v(" " + _vm._s(_vm.beastMasterProgress(_vm.user.items.pets)) + " ")])])])]), _c('div', {
    staticClass: "stats-section-mounts col-12 col-md-6"
  }, [_vm._m(1), _c('div', {
    staticClass: "well pet-mount-well"
  }, [_c('div', {
    staticClass: "pet-mount-well-image"
  }, [_c('div', {
    staticClass: "box",
    class: {
      white: _vm.user.items.currentMount
    }
  }, [_c('Sprite', {
    attrs: {
      "image-name": _vm.user.items.currentMount ? "stable_Mount_Icon_".concat(_vm.user.items.currentMount) : ''
    }
  })], 1)]), _c('div', {
    staticClass: "pet-mount-well-text"
  }, [_c('div', [_vm._v(_vm._s(_vm.formatAnimal(_vm.user.items.currentMount, 'mount')))]), _c('div', [_c('strong', [_vm._v(_vm._s(_vm.$t('mountsTamed')) + ":")]), _c('span', [_vm._v(_vm._s(_vm.totalCount(_vm.user.items.mounts)))])]), _c('div', [_c('strong', [_vm._v(_vm._s(_vm.$t('mountMasterProgress')) + ":")]), _c('span', [_vm._v(_vm._s(_vm.mountMasterProgress(_vm.user.items.mounts)))])])])])])]), _c('div', {
    staticClass: "row",
    attrs: {
      "id": "attributes"
    }
  }, [_c('hr', {
    staticClass: "col-12"
  }), _c('h2', {
    staticClass: "col-12"
  }, [_vm._v(" " + _vm._s(_vm.$t('attributes')) + " ")]), _vm._l(_vm.stats, function (statInfo, stat) {
    return _c('div', {
      key: stat,
      staticClass: "col-12 col-md-6"
    }, [_c('div', {
      staticClass: "row col-12 stats-column"
    }, [_c('div', {
      staticClass: "col-12 col-md-4 attribute-label"
    }, [_c('span', {
      staticClass: "hint",
      attrs: {
        "popover-title": _vm.$t(statInfo.title),
        "popover-placement": "right",
        "popover": _vm.$t(statInfo.popover),
        "popover-trigger": "mouseenter"
      }
    }), _c('div', {
      staticClass: "stat-title",
      class: stat
    }, [_vm._v(" " + _vm._s(_vm.$t(statInfo.title)) + " ")]), _c('strong', {
      staticClass: "number"
    }, [_vm._v(_vm._s(_vm._f("floorWholeNumber")(_vm.totalStatPoints(stat))))])]), _c('div', {
      staticClass: "col-12 col-md-6"
    }, [_c('ul', {
      staticClass: "bonus-stats"
    }, [_c('li', [_c('strong', [_vm._v(_vm._s(_vm.$t('level')) + ":")]), _vm._v(" " + _vm._s(_vm.statsComputed.levelBonus[stat]) + " ")]), _c('li', [_c('strong', [_vm._v(_vm._s(_vm.$t('equipment')) + ":")]), _vm._v(" " + _vm._s(_vm.statsComputed.gearBonus[stat]) + " ")]), _c('li', [_c('strong', [_vm._v(_vm._s(_vm.$t('class')) + ":")]), _vm._v(" " + _vm._s(_vm.statsComputed.classBonus[stat]) + " ")]), _c('li', [_c('strong', [_vm._v(_vm._s(_vm.$t('allocated')) + ":")]), _vm._v(" " + _vm._s(_vm.totalAllocatedStats(stat)) + " ")]), _c('li', [_c('strong', [_vm._v(_vm._s(_vm.$t('buffs')) + ":")]), _vm._v(" " + _vm._s(_vm.user.stats.buffs[stat]) + " ")])])])])]);
  })], 2), _vm.showAllocation ? _c('div', {
    attrs: {
      "id": "allocation"
    }
  }, [_c('div', {
    staticClass: "row title-row"
  }, [_c('div', {
    staticClass: "col-12 col-md-6"
  }, [_vm.userLevel100Plus ? _vm._m(2) : _vm._e(), _c('h3', [_vm._v(" " + _vm._s(_vm.$t('statPoints')) + " "), _vm.user.stats.points || _vm.userLevel100Plus ? _c('div', {
    staticClass: "counter badge badge-pill"
  }, [_vm._v(" " + _vm._s(_vm.pointsRemaining) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-12 col-md-6"
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('toggle-switch', {
    attrs: {
      "label": _vm.$t('autoAllocation')
    },
    on: {
      "change": function change($event) {
        return _vm.setAutoAllocate();
      }
    },
    model: {
      value: _vm.user.preferences.automaticAllocation,
      callback: function callback($$v) {
        _vm.$set(_vm.user.preferences, "automaticAllocation", $$v);
      },
      expression: "user.preferences.automaticAllocation"
    }
  })], 1)])]), _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.allocateStatsList, function (statInfo, stat) {
    return _c('div', {
      key: stat,
      staticClass: "col-12 col-md-3"
    }, [_c('div', {
      staticClass: "box white row col-12"
    }, [_c('div', {
      staticClass: "col-9 text-nowrap"
    }, [_c('div', {
      class: stat
    }, [_vm._v(" " + _vm._s(_vm.$t(_vm.stats[stat].title)) + " ")]), _c('div', {
      staticClass: "number"
    }, [_vm._v(" " + _vm._s(_vm.totalAllocatedStats(stat)) + " ")]), _c('div', {
      staticClass: "points"
    }, [_vm._v(" " + _vm._s(_vm.$t('pts')) + " ")])]), _c('div', {
      staticClass: "col-3"
    }, [_c('div', [_vm.showStatsSave ? _c('div', {
      staticClass: "up",
      on: {
        "click": function click($event) {
          return _vm.allocate(stat);
        }
      }
    }) : _vm._e()]), _c('div', [_vm.showStatsSave ? _c('div', {
      staticClass: "down",
      on: {
        "click": function click($event) {
          return _vm.deallocate(stat);
        }
      }
    }) : _vm._e()])])])]);
  }), 0), _vm.showStatsSave ? _c('div', {
    staticClass: "row save-row"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.saveAttributes();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.loading ? _vm.$t('loading') : _vm.$t('save')) + " ")])]) : _vm._e()]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('pets')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('mounts')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('noMoreAllocate'))
    }
  });

}]

export { render, staticRenderFns }