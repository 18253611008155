var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bottom-banner"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center align-items-center mt-3"
  }, [_c('span', {
    staticClass: "svg svg-icon sparkles",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.sparkles)
    }
  }), _vm._m(0), _vm._m(1)]), _vm._m(2)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', {
    staticClass: "mx-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('lookingForMore')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "svg svg-icon sparkles mirror",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.sparkles)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "check-link"
  }, [_c('span', [_vm._v("Check out the ")]), _c('a', {
    attrs: {
      "href": "/shops/customizations"
    }
  }, [_vm._v("Customizations Shop")]), _c('span', [_vm._v(" for even more ways to customize your avatar!")])]);

}]

export { render, staticRenderFns }