var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showChatWarning ? _c('base-banner', {
    staticClass: "chat-banner",
    class: {
      faq: _vm.faqPage
    },
    attrs: {
      "banner-id": "chat-warning",
      "banner-class": "chat-banner",
      "height": "3rem"
    }
  }, [_c('div', {
    staticClass: "w-100 text-center",
    attrs: {
      "slot": "content"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.$t('chatSunsetWarning'))
    },
    slot: "content"
  })]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }