var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "customize-section d-flex flex-column",
    class: {
      'justify-content-between': !_vm.showEmptySection
    },
    attrs: {
      "id": "extra"
    }
  }, [_c('sub-menu', {
    staticClass: "text-center",
    attrs: {
      "items": _vm.extraSubMenuItems,
      "active-sub-page": _vm.activeSubPage
    },
    on: {
      "changeSubPage": function changeSubPage($event) {
        return _vm.changeSubPage($event);
      }
    }
  }), _vm.activeSubPage === 'glasses' ? _c('div', {
    attrs: {
      "id": "hair-color"
    }
  }, [_c('customize-options', {
    attrs: {
      "items": _vm.eyewear
    }
  })], 1) : _vm._e(), _vm.activeSubPage === 'ears' ? _c('div', {
    attrs: {
      "id": "animal-ears"
    }
  }, [_vm.animalItems('back').length > 0 ? _c('customize-options', {
    attrs: {
      "items": _vm.animalItems('headAccessory')
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.activeSubPage === 'tails' ? _c('div', {
    attrs: {
      "id": "animal-tails"
    }
  }, [_vm.animalItems('back').length > 0 ? _c('customize-options', {
    attrs: {
      "items": _vm.animalItems('back')
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.activeSubPage === 'headband' ? _c('div', {
    attrs: {
      "id": "headband"
    }
  }, [_c('customize-options', {
    attrs: {
      "items": _vm.headbands
    }
  })], 1) : _vm._e(), _vm.activeSubPage === 'wheelchair' ? _c('div', {
    attrs: {
      "id": "wheelchairs"
    }
  }, [_c('customize-options', {
    attrs: {
      "items": _vm.chairs
    }
  })], 1) : _vm._e(), _vm.activeSubPage === 'flower' ? _c('div', {
    attrs: {
      "id": "flowers"
    }
  }, [_c('customize-options', {
    attrs: {
      "items": _vm.flowers
    }
  })], 1) : _vm._e(), _vm.showEmptySection ? _c('div', {
    staticClass: "my-5"
  }, [_vm._m(0), _vm._m(1)]) : _vm.editing ? _c('customize-banner') : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', [_vm._v(" " + _vm._s(_vm.$t('noItemsOwned')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "w-50 mx-auto",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('visitCustomizationsShop'))
    }
  });

}]

export { render, staticRenderFns }