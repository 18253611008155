var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "birthday-modal",
      "hide-header": true,
      "hide-footer": true
    }
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "modal-close",
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_c('div', {
    staticClass: "svg-icon svg-close",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.close)
    }
  })]), _c('div', {
    staticClass: "svg-confetti svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.confetti)
    }
  }), _c('div', [_c('img', {
    staticClass: "ten-birthday",
    attrs: {
      "src": require("@/assets/images/10-birthday.png")
    }
  })]), _c('div', {
    staticClass: "limited-wrapper"
  }, [_c('div', {
    staticClass: "svg-gifts svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gifts)
    }
  }), _c('div', {
    staticClass: "limited-event"
  }, [_vm._v(" " + _vm._s(_vm.$t('limitedEvent')) + " ")]), _c('div', {
    staticClass: "dates"
  }, [_vm._v(" " + _vm._s(_vm.$t('anniversaryLimitedDates')) + " ")]), _c('div', {
    staticClass: "svg-gifts-flip svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gifts)
    }
  })]), _c('div', {
    staticClass: "celebrate d-flex justify-content-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('celebrateAnniversary')) + " ")]), _c('h2', {
    staticClass: "d-flex justify-content-center"
  }, [_c('span', {
    staticClass: "left-divider",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.divider)
    }
  }), _c('span', {
    staticClass: "svg-cross",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.cross)
    }
  }), _vm._v(" " + _vm._s(_vm.$t('jubilantGryphatricePromo')) + " "), _c('span', {
    staticClass: "svg-cross",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.cross)
    }
  }), _c('span', {
    staticClass: "right-divider"
  })]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "jubilant-gryphatrice d-flex mr-auto"
  }, [_c('img', {
    attrs: {
      "src": "https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphatrice-Jubilant-Large.gif",
      "width": "156px",
      "height": "144px",
      "alt": "a pink, purple, and green gryphatrice pet winks at you adorably"
    }
  })]), _c('div', {
    staticClass: "align-items-center"
  }, [_c('div', {
    staticClass: "limited-edition mr-auto"
  }, [_vm._v(" " + _vm._s(_vm.$t('limitedEdition')) + " ")]), _c('div', {
    staticClass: "gryphatrice-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('anniversaryGryphatriceText')) + " ")]), _c('div', {
    staticClass: "gryphatrice-price",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('anniversaryGryphatricePrice'))
    }
  })])]), !_vm.ownGryphatrice && !_vm.gryphBought ? _c('div', [_vm.selectedPage !== 'payment-buttons' ? _c('div', {
    staticClass: "d-flex justify-content-center",
    attrs: {
      "id": "initial-buttons"
    }
  }, [_c('button', {
    staticClass: "btn btn-secondary buy-now-left",
    class: {
      active: _vm.selectedPage === 'payment-buttons'
    },
    on: {
      "click": function click($event) {
        _vm.selectedPage = 'payment-buttons';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('buyNowMoneyButton')) + " ")]), _c('button', {
    staticClass: "btn btn-secondary buy-now-right",
    on: {
      "click": function click($event) {
        return _vm.buyGryphatriceGems();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('buyNowGemsButton')) + " ")])]) : _vm.selectedPage === 'payment-buttons' ? _c('div', {
    staticClass: "d-flex flex-column",
    attrs: {
      "id": "payment-buttons"
    }
  }, [_c('button', {
    staticClass: "btn btn-secondary d-flex stripe",
    on: {
      "click": function click($event) {
        return _vm.redirectToStripe({
          sku: 'price_0MPZ6iZCD0RifGXlLah2furv'
        });
      }
    }
  }, [_c('span', {
    staticClass: "svg-stripe",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.stripe)
    }
  })]), _c('button', {
    staticClass: "btn btn-secondary d-flex paypal",
    on: {
      "click": function click($event) {
        return _vm.openPaypal({
          url: _vm.paypalCheckoutLink,
          type: 'sku',
          sku: 'Pet-Gryphatrice-Jubilant'
        });
      }
    }
  }, [_c('span', {
    staticClass: "svg-paypal",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.paypal)
    }
  })]), _c('amazon-button', {
    staticClass: "btn btn-secondary d-flex amazon",
    attrs: {
      "disabled": _vm.disabled,
      "amazon-data": _vm.amazonData
    },
    domProps: {
      "innerHTML": _vm._s(_vm.icons.amazon)
    }
  }), _c('div', {
    staticClass: "pay-with-gems",
    on: {
      "click": function click($event) {
        _vm.selectedPage = 'initial-buttons';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('wantToPayWithGemsText')) + " ")])], 1) : _vm._e()]) : _c('div', {
    staticClass: "d-flex"
  }, [_c('button', {
    staticClass: "own-gryphatrice-button",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('ownJubilantGryphatrice'))
    },
    on: {
      "click": function click($event) {
        return _vm.closeAndRedirect('/inventory/stable');
      }
    }
  })]), _c('h2', {
    staticClass: "d-flex justify-content-center"
  }, [_c('span', {
    staticClass: "left-divider",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.divider)
    }
  }), _c('span', {
    staticClass: "svg-cross",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.cross)
    }
  }), _vm._v(" " + _vm._s(_vm.$t('plentyOfPotions')) + " "), _c('span', {
    staticClass: "svg-cross",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.cross)
    }
  }), _c('span', {
    staticClass: "right-divider"
  })]), _c('div', {
    staticClass: "plenty-of-potions d-flex"
  }, [_vm._v(" " + _vm._s(_vm.$t('plentyOfPotionsText')) + " ")]), _c('div', {
    staticClass: "potions"
  }, [_c('div', {
    staticClass: "pot-1"
  }, [_c('img', {
    attrs: {
      "src": "https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Porcelain.png"
    }
  })]), _c('div', {
    staticClass: "pot-2"
  }, [_c('img', {
    attrs: {
      "src": "https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Vampire.png"
    }
  })]), _c('div', {
    staticClass: "pot-3"
  }, [_c('img', {
    attrs: {
      "src": "https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Aquatic.png"
    }
  })]), _c('div', {
    staticClass: "pot-4"
  }, [_c('img', {
    attrs: {
      "src": "https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_StainedGlass.png"
    }
  })]), _c('div', {
    staticClass: "pot-5"
  }, [_c('img', {
    attrs: {
      "src": "https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Celestial.png"
    }
  })]), _c('div', {
    staticClass: "pot-6"
  }, [_c('img', {
    attrs: {
      "src": "https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Glow.png"
    }
  })]), _c('div', {
    staticClass: "pot-7"
  }, [_c('img', {
    attrs: {
      "src": "https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_AutumnLeaf.png"
    }
  })]), _c('div', {
    staticClass: "pot-8"
  }, [_c('img', {
    attrs: {
      "src": "https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_SandSculpture.png"
    }
  })]), _c('div', {
    staticClass: "pot-9"
  }, [_c('img', {
    attrs: {
      "src": "https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Peppermint.png"
    }
  })]), _c('div', {
    staticClass: "pot-10"
  }, [_c('img', {
    attrs: {
      "src": "https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Shimmer.png"
    }
  })])]), _c('button', {
    staticClass: "btn btn-secondary d-flex justify-content-center visit-the-market",
    on: {
      "click": function click($event) {
        return _vm.closeAndRedirect('/shops/market');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('visitTheMarketButton')) + " ")]), _c('h2', {
    staticClass: "d-flex justify-content-center"
  }, [_c('span', {
    staticClass: "left-divider",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.divider)
    }
  }), _c('span', {
    staticClass: "svg-cross",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.cross)
    }
  }), _vm._v(" " + _vm._s(_vm.$t('fourForFree')) + " "), _c('span', {
    staticClass: "svg-cross",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.cross)
    }
  }), _c('span', {
    staticClass: "right-divider"
  })]), _c('div', {
    staticClass: "four-for-free"
  }, [_vm._v(" " + _vm._s(_vm.$t('fourForFreeText')) + " ")]), _c('div', {
    staticClass: "four-grid d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "day-one-a"
  }, [_c('div', {
    staticClass: "day-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('dayOne')) + " ")]), _c('div', {
    staticClass: "gift d-flex justify-content-center align-items-middle"
  }, [_c('img', {
    staticClass: "m-auto",
    attrs: {
      "src": require("@/assets/images/robes.webp"),
      "width": "40px",
      "height": "66px"
    }
  })]), _c('div', {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.$t('partyRobes')) + " ")])]), _c('div', {
    staticClass: "day-one-b"
  }, [_c('div', {
    staticClass: "day-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('dayOne')) + " ")]), _c('div', {
    staticClass: "gift d-flex justify-content-center align-items-middle"
  }, [_c('div', {
    staticClass: "svg-gem svg-icon m-auto",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.birthdayGems)
    }
  })]), _c('div', {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.$t('twentyGems')) + " ")])]), _c('div', {
    staticClass: "day-five"
  }, [_c('div', {
    staticClass: "day-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('dayFive')) + " ")]), _c('div', {
    staticClass: "gift d-flex justify-content-center align-items-middle"
  }, [_c('img', {
    staticClass: "m-auto",
    attrs: {
      "src": require("@/assets/images/habitica-hero-goober.webp")
    }
  })]), _c('div', {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.$t('birthdaySet')) + " ")])]), _c('div', {
    staticClass: "day-ten"
  }, [_c('div', {
    staticClass: "day-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('dayTen')) + " ")]), _c('div', {
    staticClass: "gift d-flex justify-content-center align-items-middle"
  }, [_c('div', {
    staticClass: "svg-background svg-icon m-auto",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.birthdayBackground)
    }
  })]), _c('div', {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.$t('background')) + " ")])])])]), _c('div', {
    staticClass: "modal-bottom"
  }, [_c('div', {
    staticClass: "limitations d-flex justify-content-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('limitations')) + " ")]), _c('div', {
    staticClass: "fine-print"
  }, [_vm._v(" " + _vm._s(_vm.$t('anniversaryLimitations')) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }