var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('label', {
    staticClass: "mr-3",
    attrs: {
      "for": "displayName"
    }
  }, [_vm._v(_vm._s(_vm.$t('displayName')))]), _c('div', {
    staticClass: "flex-grow-1"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.temporaryDisplayName,
      expression: "temporaryDisplayName"
    }],
    staticClass: "form-control",
    class: {
      'is-invalid input-invalid': _vm.displayNameInvalid,
      'input-valid': _vm.displayNameValid,
      'text-darker': _vm.temporaryDisplayName.length > 0
    },
    attrs: {
      "id": "displayName",
      "type": "text",
      "placeholder": _vm.$t('newDisplayName')
    },
    domProps: {
      "value": _vm.temporaryDisplayName
    },
    on: {
      "blur": function blur($event) {
        return _vm.restoreEmptyDisplayName();
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.temporaryDisplayName = $event.target.value;
      }
    }
  })])])]), _vm.displayNameIssues.length > 0 ? _c('div', {
    staticClass: "mb-3"
  }, _vm._l(_vm.displayNameIssues, function (issue) {
    return _c('div', {
      key: issue,
      staticClass: "input-error text-center"
    }, [_vm._v(" " + _vm._s(issue) + " ")]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('label', {
    staticClass: "mr-3",
    attrs: {
      "for": "username"
    }
  }, [_vm._v(_vm._s(_vm.$t('username')))]), _c('div', {
    staticClass: "flex-grow-1"
  }, [_c('div', {
    staticClass: "input-group-prepend input-group-text"
  }, [_vm._v(" @ "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.temporaryUsername,
      expression: "temporaryUsername"
    }],
    staticClass: "form-control",
    class: {
      'is-invalid input-invalid': _vm.usernameInvalid,
      'input-valid': _vm.usernameValid,
      'text-darker': _vm.temporaryUsername.length > 0
    },
    attrs: {
      "id": "username",
      "type": "text",
      "placeholder": _vm.$t('newUsername')
    },
    domProps: {
      "value": _vm.temporaryUsername
    },
    on: {
      "blur": function blur($event) {
        return _vm.restoreEmptyUsername();
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.temporaryUsername = $event.target.value;
      }
    }
  })])])])]), _vm.usernameIssues.length > 0 ? _c('div', {
    staticClass: "mb-3"
  }, _vm._l(_vm.usernameIssues, function (issue) {
    return _c('div', {
      key: issue,
      staticClass: "input-error text-center"
    }, [_vm._v(" " + _vm._s(issue) + " ")]);
  }), 0) : _vm._e(), !_vm.avatarIntro ? _c('div', {
    staticClass: "small text-center mb-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('usernameLimitations')) + " ")]) : _vm._e(), _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    class: {
      disabled: _vm.usernameCannotSubmit
    },
    attrs: {
      "type": "submit",
      "disabled": _vm.usernameCannotSubmit
    },
    on: {
      "click": function click($event) {
        return _vm.submitNames();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.avatarIntro ? 'getStarted' : 'saveAndConfirm')) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }