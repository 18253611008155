var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-dropdown', {
    staticClass: "select-list",
    class: {
      'inline-dropdown': _vm.inlineDropdown
    },
    attrs: {
      "toggle-class": _vm.isOpened ? 'active' : null,
      "disabled": _vm.disabled,
      "right": _vm.right
    },
    on: {
      "show": function show($event) {
        _vm.isOpened = true;
      },
      "hide": function hide($event) {
        _vm.isOpened = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_vm._t("item", function () {
          return [_vm._v(" " + _vm._s(_vm.value) + " ")];
        }, {
          "item": _vm.selected || _vm.placeholder,
          "button": true
        })];
      },
      proxy: true
    }], null, true)
  }, _vm._l(_vm.items, function (item) {
    return _c('b-dropdown-item', {
      key: _vm.getKeyProp(item),
      class: {
        active: _vm.isSelected(item),
        selectListItem: true,
        showIcon: !_vm.hideIcon && _vm.isSelected(item)
      },
      attrs: {
        "disabled": _vm.isDisabled(item),
        "active": _vm.isSelected(item)
      },
      on: {
        "click": function click($event) {
          return _vm.selectItem(item);
        }
      }
    }, [_vm._t("item", function () {
      return [_vm._v(" " + _vm._s(item) + " ")];
    }, {
      "item": item,
      "button": false
    }), _vm._o(_c('div', {
      staticClass: "svg-icon color check-icon",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.check)
      }
    }), 0, _vm.getKeyProp(item))], 2);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }