var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "choose-class",
      "size": "lg",
      "hide-header": true,
      "hide-footer": true,
      "no-close-on-esc": true,
      "no-close-on-backdrop": true
    }
  }, [_c('div', {
    staticClass: "modal-body select-class"
  }, [_c('h1', {
    staticClass: "header-purple text-center mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('chooseClass')) + " ")]), _c('div', {
    staticClass: "container-fluid"
  }, [_c('br'), _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.classes, function (heroClass) {
    return _c('div', {
      key: "".concat(heroClass, "-avatar"),
      staticClass: "col-md-3"
    }, [_c('div', {
      on: {
        "click": function click($event) {
          _vm.selectedClass = heroClass;
        }
      }
    }, [_c('avatar', {
      class: _vm.selectionBox(_vm.selectedClass, heroClass),
      attrs: {
        "member": _vm.user,
        "avatar-only": true,
        "with-background": false,
        "override-avatar-gear": _vm.classGear(heroClass),
        "hide-class-badge": true,
        "sprites-margin": '20px 36px 36px 20px',
        "override-top-padding": '0px',
        "show-visual-buffs": false
      }
    })], 1)]);
  }), 0), _c('br'), _vm._l(_vm.classes, function (heroClass) {
    return _c('div', {
      key: heroClass,
      staticClass: "d-flex justify-content-center"
    }, [_vm.selectedClass === heroClass ? _c('div', {
      staticClass: "d-inline-flex"
    }, [_c('div', {
      staticClass: "class-badge d-flex justify-content-center"
    }, [_c('div', {
      staticClass: "svg-icon align-self-center",
      domProps: {
        "innerHTML": _vm._s(_vm.icons[heroClass])
      }
    })]), _c('div', {
      staticClass: "class-name",
      class: "".concat(heroClass, "-color")
    }, [_vm._v(" " + _vm._s(_vm.$t(heroClass)) + " ")])]) : _vm._e()]);
  }), _vm._l(_vm.classes, function (heroClass) {
    return _c('div', {
      key: "".concat(heroClass, "-explanation")
    }, [_vm.selectedClass === heroClass ? _c('div', {
      staticClass: "class-explanation text-center"
    }, [_vm._v(" " + _vm._s(_vm.$t("".concat(heroClass, "Text"))) + " ")]) : _vm._e()]);
  }), _c('div', {
    directives: [{
      name: "markdown",
      rawName: "v-markdown",
      value: _vm.$t('chooseClassLearnMarkdown'),
      expression: "$t('chooseClassLearnMarkdown')"
    }],
    staticClass: "text-center"
  }), _c('div', {
    staticClass: "modal-actions text-center"
  }, [!_vm.selectedClass ? _c('button', {
    staticClass: "btn btn-primary d-inline-block",
    attrs: {
      "disabled": true
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('select')) + " ")]) : _c('button', {
    staticClass: "btn btn-primary d-inline-block",
    on: {
      "click": function click($event) {
        _vm.clickSelectClass(_vm.selectedClass);
        _vm.close();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('selectClass', {
    heroClass: _vm.$t(_vm.selectedClass)
  })) + " ")]), _c('div', {
    staticClass: "opt-out-wrapper"
  }, [_c('span', {
    staticClass: "danger mb-0",
    attrs: {
      "id": "classOptOutBtn"
    },
    on: {
      "click": function click($event) {
        _vm.clickDisableClasses();
        _vm.close();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('optOutOfClasses')))])]), _c('span', {
    staticClass: "opt-out-description"
  }, [_vm._v(_vm._s(_vm.$t('optOutOfClassesText')))])])], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }