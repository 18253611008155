var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "label-line"
  }, [_vm.settingsLabel ? _c('div', {
    staticClass: "settings-label"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.settingsLabel)) + " ")]) : _vm._e(), _vm._t("top-right")], 2), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "input-group",
    class: {
      'is-valid': _vm.validStyle,
      'is-invalid': _vm.invalidStyle
    }
  }, [_c('input', {
    staticClass: "form-control",
    class: {
      'is-invalid input-invalid': _vm.invalidStyle,
      'is-valid input-valid': _vm.validStyle
    },
    attrs: {
      "type": _vm.inputType,
      "readonly": _vm.readonly,
      "aria-readonly": _vm.readonly,
      "placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "keyup": _vm.handleChange,
      "blur": function blur($event) {
        return _vm.$emit('blur');
      }
    }
  })]), _vm._l(_vm.invalidIssues, function (issue) {
    return _c('div', {
      key: issue,
      staticClass: "input-error"
    }, [_vm._v(" " + _vm._s(issue) + "   ")]);
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }