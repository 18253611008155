var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "notification dropdown-item dropdown-separated d-flex justify-content-between",
    on: {
      "click": _vm.clicked
    }
  }, [_vm.hasIcon ? _c('div', {
    staticClass: "notification-icon d-flex justify-content-center align-items-center",
    class: {
      'is-not-bailey': _vm.isNotBailey
    }
  }, [_vm._t("icon")], 2) : _vm._e(), _c('div', {
    staticClass: "notification-content",
    class: {
      'has-text': _vm.hasText
    }
  }, [_vm._t("content")], 2), _c('div', {
    staticClass: "notification-remove",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.canRemove ? _vm.remove() : null;
      }
    }
  }, [_vm.canRemove ? _c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.close)
    }
  }) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }