var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-notification', {
    attrs: {
      "can-remove": _vm.canRemove,
      "has-icon": true,
      "notification": _vm.notification,
      "read-after-click": false
    },
    on: {
      "click": _vm.action
    }
  }, [_c('div', {
    attrs: {
      "slot": "content"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.$t('cardReceived', {
        card: _vm.cardString
      }))
    },
    slot: "content"
  }), _c('div', {
    class: _vm.cardClass,
    attrs: {
      "slot": "icon"
    },
    slot: "icon"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }